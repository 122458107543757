/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  QuickLinks,
  Search,
  ThemeModeSwitcher,
} from '../../../partials'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import LoginTypes from '../../../../_MobixaPlus/ReduxNew/reducers/LoginRedux'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { Avatar, Box, Typography } from '@mui/material'
import CircularProgress from '@material-ui/core/CircularProgress'

const ButtonMarginClass = 'ms-1 ms-lg-3'
const ButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px'
const UserAvatarHeightClass = 'symbol-30px symbol-md-40px'
const ButtonIconSizeClass = 'svg-icon-1'

// Ghost Login Hook
const useGhostLogin = (loginData: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const performGhostLogin = async (customer: number, user: number) => {
    try {
      // Clear existing session
      sessionStorage.clear()
      localStorage.clear()

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/ghostSignIn`,
        {
          customerId: customer,
          userId: user,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data) {
        // Update storage with new data
        sessionStorage.setItem('auth', JSON.stringify(response.data))
        localStorage.setItem('user', JSON.stringify(response.data.customerId))

        // Update Redux store
        await dispatch(LoginTypes.logout())
        await dispatch(LoginTypes.loginSuccess(response.data))

        // Navigate and reload
        navigate('/')
        setTimeout(() => {
          window.location.reload()
        }, 100)

        return true
      }
      return false
    } catch (error) {
      console.error('Ghost login failed:', error)
      dispatch(LoginTypes.logout())
      navigate('/login')
      return false
    }
  }

  return { performGhostLogin }
}

const Topbar: FC = () => {
  const LoginState = useSelector((state: any) => state.Login)
  const customerProfile = useSelector((state: any) => state.Login.customerProfile)
  const [products, setProducts] = useState<any>()
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const { performGhostLogin } = useGhostLogin(LoginState.loginData)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(LoginTypes.logout())
    navigate(`/login`)
  }

  useEffect(() => {
    setProducts(customerProfile)
    setisAdmin(customerProfile?.isSystemAdmin)
  }, [customerProfile])

  const [customer, setCustomer] = useState<any>([])
  useEffect(() => {
    async function listCustomerManagers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/ghostSignIn/listCustomerManagers`,
          {
            headers: {
              accept: 'application/json',
              Authorization: `Bearer ${LoginState.loginData.access_token}`,
            },
          }
        )
        setCustomer(response.data)
      } catch (error) {
        console.error('Failed to fetch customer managers:', error)
      }
    }
    listCustomerManagers()
  }, [LoginState.loginData])

  const handleGhostSignIn = async (customer: number, user: number) => {
    if (isLoggingIn) return
    setIsLoggingIn(true)
    try {
      await performGhostLogin(customer, user)
    } finally {
      setIsLoggingIn(false)
    }
  }

  const [isAdmin, setisAdmin] = useState<any>(false)
  const [adminRoles, setisAdminRoles] = useState<any>([])

  useEffect(() => {
    setisAdminRoles(products?.role.map((li: any) => li.roleId))
  }, [products])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [nestedAnchorEl, setNestedAnchorEl] = useState<null | HTMLElement>(null)
  const nestedMenuRef = useRef<any>(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSubMenuOpen = (event: React.MouseEvent<HTMLLIElement>) => {
    setNestedAnchorEl(event.currentTarget)
  }

  const [activeItem, setActiveItem] = useState('')

  const handleMenuClose = () => {
    setAnchorEl(null)
    setNestedAnchorEl(null)
  }

  const handleSubMenuClose = () => {
    setNestedAnchorEl(null)
  }

  const handleMenuItemClick = (itemName: string) => {
    setActiveItem(itemName)
  }

  interface SubMenuProps {
    open: boolean
    anchorEl: HTMLElement | null
    onClose: () => void
    name?: any
  }

  function SubMenu({ open, anchorEl, onClose, name }: SubMenuProps) {
    return (
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            background: '#009998',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Object.entries(customer).map(([key, value]: [string, any]) => {
          if (key === name) {
            return (
              <div key={key}>
                {Object.values(value).map((item: any) => (
                  <MenuItem
                    key={item.userId}
                    onClick={() => handleGhostSignIn(item.customerId, item.userId)}
                    disabled={isLoggingIn}
                    style={{
                      ...menuItemStyle,
                      opacity: isLoggingIn ? 0.7 : 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: '8px',
                    }}
                  >
                    <span>{`${item.name} ${item.surname}, ${item.roleName.join(', ')}`}</span>
                    {isLoggingIn && <CircularProgress size={20} style={{ color: 'white' }} />}
                  </MenuItem>
                ))}
              </div>
            )
          }
          return null
        })}
      </Menu>
    )
  }

  const menuItemStyle = {
    border: '1px solid #fff',
    borderRadius: '5px',
    margin: '0px',
    backgroundColor: '#009998',
    color: '#fff',
    paddingtop: '0px',
    fontSize: '16px',
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        <div
          className={clsx('d-flex align-items-center', ButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          <div style={{ marginLeft: '10rem' }}></div>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'end',
              }}
            >
              <Button disabled={isAdmin === true ? false : true} onClick={handleMenuOpen}>
                <Typography style={{ fontSize: '16px', color: '#fff', fontWeight: 'bold' }}>
                  {products?.name.charAt(0) + '. ' + products?.surname}
                </Typography>
              </Button>
              {isAdmin && (
                <Typography style={{ fontSize: '14px', color: '#fff', marginRight: '10px' }}>
                  {customerProfile?.customerName}
                </Typography>
              )}
            </div>

            <Menu
              anchorEl={anchorEl}
              style={{ marginTop: '35px' }}
              PaperProps={{
                style: {
                  background: '#009998',
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              className='menu-root'
            >
              {Object.keys(customer).map((key: any, i: any) => (
                <MenuItem
                  key={i}
                  onClick={() => handleMenuItemClick(i)}
                  className={`menuu-item ${activeItem === i ? 'active' : ''}`}
                  style={menuItemStyle}
                  onMouseEnter={(e) => {
                    handleSubMenuOpen(e)
                    handleMenuItemClick('seçenek2')
                  }}
                  ref={nestedMenuRef}
                >
                  {key}
                  {activeItem === i && (
                    <SubMenu
                      open={Boolean(nestedAnchorEl)}
                      anchorEl={nestedAnchorEl}
                      onClose={handleSubMenuClose}
                      name={key}
                    />
                  )}
                </MenuItem>
              ))}
            </Menu>
            <style jsx>{`
              .menu-root {
                position: absolute;
                font-size: 16px;
              }
              .menuu-item:hover:not(.submenu-item),
              .menuu-item.active {
                background-color: #fff !important;
                color: #333 !important;
                fontweight: bold;
              }
              .submenu-item {
                position: relative;
              }

              .MuiList-padding {
                padding: 0px;
              }
              .submenu-item:hover > .MuiMenu-root {
                left: -100%;
              }
            `}</style>
          </div>
          <div
            className={clsx('cursor-pointer symbol', UserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            {(products?.avatarPath && (
              <Avatar variant='circular' sx={{ border: '2px solid white', width: '52px', height: '52px' }}>
                <Avatar src={products?.avatarPath} variant='circular' sx={{ width: '50px', height: '50px' }} />
              </Avatar>
            )) || (
              <img
                className='h-46px w-46px rounded'
                src={toAbsoluteUrl('/media/avatars/blank.png')}
                alt='metronic'
              />
            )}
          </div>
          <HeaderUserMenu user={products} />
        </div>
      </div>
    </div>
  )
}

export { Topbar }