import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { PageLink } from '../../../_metronic/layout/core'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
  Slide,
  Tooltip,
  Typography,
  darken,
  lighten,
  styled,
} from '@mui/material'
import {
  DataGridPro,
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useSelector } from 'react-redux'
import { TransitionProps } from '@mui/material/transitions'
import { useNavigate } from 'react-router-dom'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import GroupsIcon from '@mui/icons-material/Groups'
import { end } from '@popperjs/core'

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

function JourneysReport() {
  const LoginState = useSelector((state: any) => state.Login)
  const navigate = useNavigate()
  const QuestionsBreadcrumbs: Array<PageLink> = [
    {
      title: 'Reports',
      // path: '/apps/user-management/users',
      path: '/reports',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  })
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [selectQuestion, setSelectQuestion] = useState([])
  const [questionStatus, setQuestionStatus] = useState(-1)
  const [journeyOptions, setJourneyOptions] = useState<any>({
    sortModel: [{ field: 'journeyId', sort: 'desc' }],
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    /*    id: false,
           brokerId: false,
           status: false, */
  })

  const [status] = useState([
    { value: 0, label: 'PAUSED' },
    { value: 1, label: 'READY' },
    { value: 2, label: 'STARTED' },
    { value: 3, label: 'FINISHED' },
    { value: 5, label: 'DRAFT' },
  ])
  const [chat] = useState([
    { value: 'On', label: 'On' },
    { value: 'Off', label: 'Off' },
  ])
  const [type] = useState([
    { value: 'PRIVATE', label: 'Private' },
    { value: 'PUBLIC', label: 'Public' },
  ])

  const StatusCell = ({ value }: { value: number }) => {
    const getStatusComponent = (status: number) => {
      if (status === 0) {
        return (
          <Chip
            className='overflow-visible'
            label='PAUSED'
            variant='outlined'
            size='small'
            color='default'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 1) {
        return (
          <Chip
            className='overflow-visible'
            label='READY'
            variant='outlined'
            size='small'
            color='primary'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 2) {
        return (
          <Chip
            className='overflow-visible'
            label='STARTED'
            variant='outlined'
            size='small'
            color='success'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 3) {
        return (
          <Chip
            className='overflow-visible'
            label='FINISHED'
            variant='outlined'
            size='small'
            color='secondary'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 5) {
        return (
          <Chip
            className='overflow-visible'
            label='DRAFT'
            variant='outlined'
            size='small'
            color='success'
            sx={{
              overflow: 'visible',
              maxWidth: '90px',
              fontSize: '13px',
              background: '#cccccc',
              color: '#707070',
            }}
          />
        )
      }
      if (status === 6) {
        return (
          <Chip
            className='overflow-visible'
            label='STOPPED'
            variant='outlined'
            size='small'
            color='secondary'
            sx={{
              overflow: 'visible',
              maxWidth: '90px',
              fontSize: '13px',
              /*     background: '#cccccc',
                  color: '#707070', */
            }}
          />
        )
      }
      return null
    }

    return <div style={{ height: '19px', width: '57px' }}>{getStatusComponent(value)}</div>
  }
  const [award] = useState([
    { value: 0, label: 'None' },
    { value: 1, label: 'Champion' },
    { value: 2, label: '3 Winners' },
    { value: 3, label: 'Top 10' },
    { value: 4, label: 'Top 25' },
    { value: 5, label: 'Top 100' },
    { value: 6, label: 'All Finishers' },
  ])

  const AwardCell = ({ value }: { value: number }) => {
    const getStatusComponent = (status: number) => {
      if (status === 0) {
        return <Typography>None</Typography>
      }
      if (status === 1) {
        return <Typography>Champion</Typography>
      }
      if (status === 2) {
        return <Typography>3 Winners</Typography>
      }
      if (status === 3) {
        return <Typography>Top 10</Typography>
      }
      if (status === 4) {
        return <Typography>Top 25</Typography>
      }
      if (status === 5) {
        return <Typography>Top 100</Typography>
      }
      if (status === 6) {
        return <Typography>All Finishers</Typography>
      }
      return null
    }

    return <div style={{ height: '19px', width: '57px' }}>{getStatusComponent(value)}</div>
  }

  const columns: any = [
    {
      field: 'journeyId',
      headerName: 'JOURNEY ID',
      width: 140,
      type: 'number',
      headerAlign: 'left',
      align: 'start',
      valueGetter: (params: any) => params.value.toFixed(0),
    },
    {
      field: 'name',
      headerName: 'JOURNEY',
      width: 130,
      headerAlign: 'left',
      align: 'left',
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'assignmentType',
      headerName: 'TYPE',
      width: 120,
      description:
        "Private Journeys can be played by your registered users only.They need to login by using predefined username and passsword for Mobixa app. Public Journeys can be accessible for anyone on the internet. They just need to click the Journey's public link.",
      headerAlign: 'left',
      align: 'left',
      type: 'singleSelect',
      sortable: false,
      valueOptions: type.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'startDate',
      headerName: 'START DATE',
      width: 120,
      type: 'dateTime',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => {
        if (!params.value || isNaN(new Date(params.value).getTime())) {
          return ''
        }
        return new Date(params.value)
      },
    },
    //finishDate
    {
      field: 'endDate',
      headerName: 'END DATE',
      width: 120,
      type: 'dateTime',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => {
        if (!params.value || isNaN(new Date(params.value).getTime())) {
          return ''
        }
        return new Date(params.value)
      },
    },
    {
      field: 'successRate',
      headerName: (
        <Box
          display={'flex'}
          flexDirection={'column'}
          textAlign={'center'}
          justifyContent={'center'}
          marginTop={1.6}
        >
          <Typography
            display={'flex'}
            alignItems={'center'}
            fontSize={12}
            color={'#a1a5b7'}
            fontWeight={'bold'}
          >
            SUCCESS
          </Typography>
          <Typography marginLeft={1} fontSize={10}>
            %
          </Typography>
        </Box>
      ),
      width: 150,
      description: 'It is the correct answer rate of all questions shown to users in this journey.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => (params?.value ? params.value.toFixed(2) : '0.00'),
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'progressRate',
      headerName: (
        <Box
          display={'flex'}
          flexDirection={'column'}
          textAlign={'center'}
          justifyContent={'center'}
          marginTop={1.6}
        >
          <Typography
            display={'flex'}
            alignItems={'center'}
            fontSize={12}
            color={'#a1a5b7'}
            fontWeight={'bold'}
          >
            PROGRESS
          </Typography>
          <Typography marginLeft={1} fontSize={10}>
            %
          </Typography>
        </Box>
      ),
      width: 120,
      description: 'The average activity completion rate by users of this journey.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => (params?.value ? params.value.toFixed(2) : '0.00'),
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'timeFrame',
      headerName: (
        <Box
          display={'flex'}
          flexDirection={'column'}
          textAlign={'center'}
          justifyContent={'center'}
          marginTop={1.6}
        >
          <Typography
            display={'flex'}
            alignItems={'center'}
            fontSize={12}
            color={'#a1a5b7'}
            fontWeight={'bold'}
          >
            TIME FRAME
          </Typography>
          <Typography marginLeft={1} fontSize={10}>
            dd : hh : mm
          </Typography>
        </Box>
      ),
      width: 160,
      description:
        'How long does  Journey take? Time difference between start date and finish date.',
      /* type: 'dateTime', */
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'assignedUserCount',
      headerName: 'ASSIGNED USERS',
      width: 150,
      description: 'The number of total users who are assigned to the journey.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => (params.value ? params.value : '-'),
    },
    {
      field: 'completionRate',
      headerName: (
        <Box
          display={'flex'}
          flexDirection={'column'}
          textAlign={'center'}
          justifyContent={'center'}
          marginTop={1.6}
        >
          <Typography
            display={'flex'}
            alignItems={'center'}
            fontSize={12}
            color={'#a1a5b7'}
            fontWeight={'bold'}
          >
            COMPLETION
          </Typography>
          <Typography marginLeft={1} fontSize={10}>
            %
          </Typography>
        </Box>
      ),
      width: 120,
      description:
        'The ratio of the users who competed the journey over the number of all assigned users.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => (params?.value ? params.value.toFixed(2) : '0.00'),
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'activitiesCount',
      headerName: 'ACTIVITIES',
      width: 130,
      description: 'The number of activities in the journey.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'award',
      headerName: 'AWARD',
      width: 130,
      description:
        'Is there an award commited by the managers? And who  will be eligable to have the prize? ',
      headerAlign: 'left',
      type: 'singleSelect',
      align: 'left',
      sortable: false,
      valueOptions: award.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      renderCell: (params: GridCellParams) => <AwardCell value={params.value as number} />,
    },
    {
      field: 'chatIsOn',
      headerName: 'CHAT',
      width: 110,
      headerAlign: 'left',
      type: 'singleSelect',
      align: 'left',
      sortable: false,
      valueOptions: chat.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'createdBy',
      headerName: 'CREATED BY',
      width: 130,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      filterable: false,
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'statusId',
      headerName: 'STATUS',
      width: 150,
      type: 'singleSelect',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      valueOptions: status.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      renderCell: (params: GridCellParams) => <StatusCell value={params.value as number} />,
      /*    valueFormatter: (params: any) => {
              const selectedStatus = status.find((status: any) => status.value === params.value);
              if (!selectedStatus) {
                console.log('Unexpected value:', params.value);
                return null;
              }
              return selectedStatus ? <StatusCell value={params.value as number}/> : null;
            }, */
    },
  ]

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ ml: 1, mr: 1 }}
          >
            <MenuItem selected value={100}>
              100
            </MenuItem>
            <MenuItem value={150}>150</MenuItem>
            <MenuItem value={200}>200</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }

  const handleSelectionModelChange = (newSelectionModel: any) => {
    if (newSelectionModel.length > 1) {
      // Yalnızca en son seçilen öğeyi koruyun
      const newSelectedId = newSelectionModel[newSelectionModel.length - 1]
      setSelectQuestion([newSelectedId])
    } else {
      setSelectQuestion(newSelectionModel)
    }
  }

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    /*  if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    } */
    /*  setOpenUserList(true) */
    if (selectQuestion.includes(params.id)) {
      setSelectQuestion([])
    } else {
      // Değilse, tıklanan satırı seç
      setSelectQuestion([params.id])
    }
    /*  navigate(`/reports/JourneysReportDetail?id=${params.row.journeyId}&name=${params.row.name}`);
     fetchUserList(params.row.journeyId) */
    // Diğer işlemleri burada yapabilirsiniz
  }
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setJourneyOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
          return
        } */

    setJourneyOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])

  const [loadingExport, setLoadingExport] = useState(false)

  async function downloadExcel() {
    setLoadingExport(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/report/journeyReport?isExcel=1`,
        {
          columnVisibilityModel,
        },
        {
          responseType: 'blob',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (response) => {
        const excelBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const excelUrl = await URL.createObjectURL(excelBlob)

        const link = document.createElement('a')
        link.href = excelUrl
        link.download = 'journeysReport.xlsx'
        link.click()
        URL.revokeObjectURL(excelUrl)
        setLoadingExport(false)
      })
      .catch((err) => {
        setLoadingExport(false)
      })
    /* const newData = rows.map((row: any) => {
          delete row.tableData
          row.statusId = row.statusId === 1 ? 'Open' : row.statusId === 0 ? 'Block' :""
          return row
        })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, 'row')
        //Buffer
        XLSX.write(workBook, {bookType: 'xlsx', type: 'buffer'})
        //Binary string
        XLSX.write(workBook, {bookType: 'xlsx', type: 'binary'})
        //Download
        XLSX.writeFile(workBook, 'userReports.xlsx') */
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <LoadingButton
          size='small'
          onClick={downloadExcel}
          endIcon={<FileDownloadIcon />}
          loading={loadingExport}
          loadingPosition='end'
          variant='outlined'
        >
          <span>Export</span>
        </LoadingButton>
      </GridToolbarContainer>
    )
  }

  useEffect(() => {
    fetchRows()
  }, [paginationModel])

  const fetchRows = async () => {
    setIsLoadingData(true)

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/report/journeyReport?size=${paginationModel.pageSize
        }&page=${paginationModel.page + 1}`,
        {},
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const formattedRows = response.data?.items?.map((row: any) => ({
          journeyId: row.journeyId,
          name: row.name,
          assignmentType: row.assignmentType,
          startDate: row.startDate,
          endDate: row.endDate,
          successRate: row.successRate,
          progressRate: row.progressRate,
          timeFrame: row.timeFrame,
          assignedUserCount: row.assignedUserCount,
          completionRate: row.completionRate,
          activitiesCount: row.activitiesCount,
          award: row.award,
          chatIsOn: row.chatIsOn,
          createdBy: row.createdBy,
          statusId: row.statusId,
        }))
        setRows(formattedRows || [])
        setRowCount(response.data.total)
        setIsLoadingData(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
      })
  }

  const [openUserList, setOpenUserList] = useState(false)
  const [userListLoading, setUserListLoading] = useState(false)
  const [journeyId, setJourneyId] = useState(0)
  const [userList, setUserList] = useState([])

  const fetchUserList = async (journeyId: number) => {
    setUserListLoading(true)
    setJourneyId(journeyId)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/report/journeyAssignedUsers/${journeyId}`,
        {},
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        setUserList(response.data.items || [])
        setUserListLoading(false)
      })
      .catch((err) => {
        setUserListLoading(false)
      })
  }
  const [excelLoading, setExcelLoading] = useState(false)
  async function fetchUserListExcelExport() {
    setExcelLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/report/journeyAssignedUsers/${journeyId}?isExcel=1`,
        {},
        {
          responseType: 'blob',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const excelBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const excelUrl = URL.createObjectURL(excelBlob)

        const link = document.createElement('a')
        link.href = excelUrl
        link.download = `reportUserList-${journeyId}.xlsx`
        link.click()
        URL.revokeObjectURL(excelUrl)
        setExcelLoading(false)
      })
      .catch((err) => {
        setExcelLoading(false)
      })
  }
  const [hoverButton, setHoverButton] = useState({ userDetail: false, activityDetail: false })
  return (
    <>
      {/* <PageTitle breadcrumbs={QuestionsBreadcrumbs}>Journey</PageTitle> */}
      <div
        style={{
          display: 'flex',
          height: 40,
          width: '80%',
          marginBottom: '5px',
          marginTop: '-25px',
          cursor: 'default',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
          Reports - Journey
        </Typography>
      </div>
      <Box
        display='flex'
        justifyContent='flex-end'
        alignItems='center'
        alignContent='center'
      /*     sx={{ height: 50 }} */
      >
        <div style={{ marginTop: '-70px', marginRight: 90 }}>
          {selectQuestion.length > 0 && (
            <Tooltip
              arrow
              title='By Activity'
              placement='top'
              onMouseEnter={() => {
                setHoverButton((buttons) => ({ ...buttons, changeCategory: true }))
              }}
              onMouseLeave={() => {
                setHoverButton((buttons) => ({ ...buttons, changeCategory: false }))
              }}
              style={{ background: hoverButton.userDetail ? '#D5D5D5 ' : 'white' }}
            >
              <IconButton
                aria-label='By Activity'
                onClick={() => {
                  const data = rows.find((item: any) => item.journeyId === selectQuestion[0])

                  navigate(`/reports/JourneysReportActivity?id=${data.journeyId}&name=${data.name}`)
                }}
                size='small'
                sx={{
                  background: 'white',
                  color: hoverButton.userDetail ? 'black' : '',
                  marginLeft: '7px',
                }}
              >
                <ScatterPlotIcon sx={{ fontSize: '30px' }} />
              </IconButton>
            </Tooltip>
          )}
          {selectQuestion.length > 0 && (
            <Tooltip
              arrow
              title='By User'
              placement='top'
              onMouseEnter={() => {
                setHoverButton((buttons) => ({ ...buttons, changeCategory: true }))
              }}
              onMouseLeave={() => {
                setHoverButton((buttons) => ({ ...buttons, changeCategory: false }))
              }}
              style={{ background: hoverButton.userDetail ? '#D5D5D5 ' : 'white' }}
            >
              <IconButton
                aria-label='By User'
                onClick={() => {
                  const data = rows.find((item: any) => item.journeyId === selectQuestion[0])
                  navigate(`/reports/JourneysReportDetail?id=${data.journeyId}&name=${data.name}`)
                  /*  fetchUserList(params.row.journeyId) */
                }}
                size='small'
                sx={{
                  background: 'white',
                  color: hoverButton.userDetail ? 'black' : '',
                  marginLeft: '7px',
                }}
              >
                <GroupsIcon sx={{ fontSize: '30px' }} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Box>
      <div className='card card-custom p-3  '>
        <Box sx={{ height: 600, width: '100%' }}>
          <StyledDataGrid
            /*   {...data}
                loading={data.rows.length === 0} */
            rows={rows || []}
            columns={columns}
            rowHeight={50}
            columnHeaderHeight={40}
            disableVirtualization
            checkboxSelection
            onCellClick={handleCellClick}
            getRowId={(row) => row.journeyId}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            disableRowSelectionOnClick
            sortingMode='client'
            sortModel={journeyOptions?.sortModel}
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={handleSelectionModelChange}
            filterMode='client'
            onFilterModelChange={onFilterChange}
            /* isRowSelectable={(params: GridRowParams) => params.row.source === 'Client'} */
            rowCount={rowCount}
            loading={isLoadingData}
            paginationModel={paginationModel}
            rowSelectionModel={selectQuestion}
            paginationMode='client'
            pagination={true}
            onPaginationModelChange={setPaginationModel}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
            }
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
            sx={{
              boxShadow: 'none',
              border: 'none',
              '& .MuiDataGrid-footerContainer': {
                padding: '0px', // Footer'ın içerisindeki padding'i daraltmak için bu değerleri ayarlayabilirsiniz
                height: 30,
              },

              '& .MuiDataGrid-cell:hover': {
                /*    color: 'primary.main', */
                cursor: 'pointer',
              },
              '&	.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                color: '#A1A5B7',
                fontSize: 12,
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
              },
            }}
          />
        </Box>
      </div>
      <Dialog
        open={openUserList}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth='sm'
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Box display={'flex'}>
            <Typography variant='h5'>Report Journey - User Detail </Typography>
            <Box display={'flex'} marginLeft={10} justifyContent={'center'}>
              <Typography variant='h5'>{journeyId}</Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box padding={1} height={500} width={'100%'} overflow={'auto'}>
            <Box display={'flex'} justifyContent={'end'}>
              <LoadingButton
                size='small'
                onClick={fetchUserListExcelExport}
                endIcon={<FileDownloadIcon />}
                loading={excelLoading}
                loadingPosition='end'
                variant='outlined'
              >
                <span>Export</span>
              </LoadingButton>
            </Box>
            {excelLoading && (
              <Box display={'flex'} justifyContent={'center'}>
                <CircularProgress />
              </Box>
            )}
            {
              //map olacak
              userList?.map((user: any, index: number) => {
                return (
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      marginTop: index !== 0 ? '5px' : '5px',
                      paddingLeft: '5px',
                      borderBottom: '1px solid #6E6F6F',
                      paddingBottom: '5px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography>{`${index + 1} - )`}</Typography>
                      <Typography style={{ marginLeft: '10px' }} variant='subtitle1' color='#6E6F6F'>
                        {`${user.name} ${user.surname}`}
                      </Typography>
                    </Box>

                    <Box width={100}>
                      <LinearProgress
                        style={{ height: '5px', borderRadius: '5px' }}
                        color={user.progressRatio === 100 ? 'success' : 'primary'}
                        variant='determinate'
                        value={user.progressRatio}
                      />
                    </Box>
                  </Box>
                )
              })
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUserList(false)} variant='contained' color='inherit'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default JourneysReport
