import { FC, forwardRef, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { ID, initialListView, KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import axios from 'axios'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Chip,
  Slide,
  SpeedDial,
  SpeedDialAction,
  TextField,
  Tooltip,
  Typography,
  Avatar,
} from '@mui/material'
import { FilterTableQuestion } from './FilterTableQuestion'
import { useNavigate } from 'react-router-dom'
import AddQuestionModal from './AddQuestionModal'
import { WizardAddQuestion } from './AddQuestionComponenets/WizardModalQuestion'
import EditQuestionModal from './EditQuestion'
import EmojiPicker, { EmojiStyle, Theme, EmojiClickData, Emoji } from 'emoji-picker-react'
import Loading from '../../../_metronic/layout/components/Loading'
import Box from '@mui/material/Box'
import SearchIcon from '@mui/icons-material/Search'
import LoadingButton from '@mui/lab/LoadingButton'
import CheckIcon from '@mui/icons-material/Check'
import BlockIcon from '@mui/icons-material/Block'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {
  DataGridPro,
  GridCellParams,
  GridFilterModel,
  GridRowParams,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  getGridNumericOperators,
  GridFilterOperator,
  getGridStringOperators,
  GridPagination,
  GridFilterItem,
  GridColumnVisibilityModel,
  GridCallbackDetails,
} from '@mui/x-data-grid-pro'
/* import * as XLSX from 'xlsx' */
import { darken, lighten, styled } from '@mui/material/styles'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import SelectCategory from '../Administration/Components/SelectCategory'
import { SnackbarProvider, useSnackbar } from 'notistack'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { TransitionProps } from '@mui/material/transitions'
import AddIcon from '@mui/icons-material/Add'
import AddQuestionAi from './AddQuestionComponenets/addQuestionAi'
import ReactPlayer from 'react-player'
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined'
import ImportQuestionModal from './AddQuestionComponenets/importQuestionModal'
import SettingsIcon from '@mui/icons-material/Settings'

type Props = {
  id?: ID
}

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const QuestionsList: FC<Props> = ({ id }) => {
  
  /*  const rows = [
    {
      id: 1,
      media: 'Media 1',
      question:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia, quisquam. Consequuntur possimus eligendi porro hic a quos, veniam dolorem quidem perspiciatis. Officiis, commodi unde. Iusto, expedita? Quaerat blanditiis voluptas excepturi?',
      category: 'Category 1',
    },
    {
      id: 2,
      media: <img src='media2.jpg' alt='Media 2' style={{width: '30px', height: '30px'}} />,
      question: 'Question 2',
      category: 'Category 2',
    },
    {id: 3, media: 'Media 3', question: 'Question 3', category: 'Category 3'},
    {id: 4, media: 'Media 4', question: 'Question 4', category: 'Category 4'},
    {id: 5, media: 'Media 5', question: 'Question 5', category: 'Category 5'},
    {id: 6, media: 'Media 5', question: 'Question 5', category: 'Category 5'},
    {id: 7, media: 'Media 5', question: 'Question 5', category: 'Category 5'},
    {id: 8, media: 'Media 5', question: 'Question 5', category: 'Category 5'},
    {id: 9, media: 'Media 5', question: 'Question 5', category: 'Category 5'},
    {id: 10, media: 'Media 5', question: 'Question 5', category: 'Category 5'},
    {id: 11, media: 'Media 5', question: 'Question 5', category: 'Category 5'},
    {id: 12, media: 'Media 5', question: 'Question 5', category: 'Category 5'},
    {id: 13, media: 'Media 5', question: 'Question 5', category: 'Category 5'},
    {id: 14, media: 'Media 5', question: 'Question 5', category: 'Category 5'},
    {id: 15, media: 'Media 5', question: 'Question 5', category: 'Category 5'},
  ] */
  //warn modal
  const [openWarnModal, setOpenWarnModal] = useState(false)
  const getEdit = () => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    return params.get('edit')
  }
  const editQuestion = getEdit() || ""


  const customerProfile = useSelector((state: any) => state.Login.customerProfile)

  const [userRole, setUserRole] = useState<any>([])

  useEffect(() => {
    let role = customerProfile?.role
    setUserRole(role)

  }, [customerProfile])

  let roleId = [0, 1, 2, 3]
  let roleIsValid = userRole?.some((role: any) => roleId.includes(role.roleId))

  const handleClickWarnModalOpen = () => {
    setOpenWarnModal(true)
  }

  const handleClickWarnModalClose = () => {
    setOpenWarnModal(false)
    setQuestionStatus(-1)
  }
  //warn modal end

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [categories, setCategories] = useState<any>([])
  const [openCategoryModal, setOpenCategoryModal] = useState(false)
  /*  let categoriess = Array.from(new Set(categories.map((item: any) => item.name))) */
  const [status] = useState([
    /*    { value: 0, label: 'Blocked' }, */
    { value: 1, label: 'Open' },
    { value: 2, label: 'Deleted' },
  ])
  const [hoverList, setHoverList] = useState(null)

  const columns: any = [
    {
      field: 'questionId',
      headerName: 'ID',
      width: 60,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value.toFixed(0),
    },
    {
      field: 'mediaUrl',
      headerName: 'MEDIA',
      align: 'left',
      width: 150,
      filterable: false,
      renderCell: (params: any) => {
        const item = params.row
        if (item.mediaType === 1) {
          if (item.mediaUrl) {
            return (
              <div className='p-3 d-flex text-center'>
                <Avatar variant="rounded" src={item.mediaUrl} alt='media' sx={{
                  height: 50, width: 50, '& img': {
                    objectFit: 'contain',
                  },
                }} />
              </div>
            )
          } else {
            return (
              <div className='p-3'>
                <Avatar
                  variant="rounded"
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt='media' sx={{
                    height: 50, width: 50, '& img': {
                      objectFit: 'contain',
                    },
                  }}
                />
              </div>
            )
          }
        } else if (item.mediaType === 4) {
          if (item.mediaUrl) {
            return (
              <div className='p-3'>
                <Emoji unified={item.mediaUrl} emojiStyle={EmojiStyle.APPLE} size={50} />
              </div>
            )
          } else {
            return (
              <div className='p-3'>
                <Avatar
                  variant="rounded"
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt='media' sx={{
                    height: 50, width: 50, '& img': {
                      objectFit: 'contain',
                    },
                  }}
                />
              </div>
            )
          }
        } else if (item.mediaType === 2) {
          if (item.mediaUrl) {
            return (
              <div
                className='p-3 '
                /*   style={{ transform: hoverList === item.mediaUrl ? 'scale(1.5)' : 'none' }} */
                onMouseEnter={() => setHoverList(item.mediaUrl)}
                onMouseLeave={() => setHoverList(null)}
              >
                <Avatar
                  variant="rounded"
                  src={item.mediaUrl}
                  alt='media' sx={{
                    height: 50, width: 50, '& img': {
                      objectFit: 'contain',
                    },
                  }}
                />
              </div>
            )
          } else {
            return (
              <div className='p-3'>
                <Avatar
                  variant="rounded"
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt='media' sx={{
                    height: 50, width: 50, '& img': {
                      objectFit: 'contain',
                    },
                  }}
                />
              </div>
            )
          }
        } else {
          return (
            <div className='p-3'>
              <Avatar
                variant="rounded"
                src={toAbsoluteUrl('/media/svg/image.svg')}
                alt='media' sx={{
                  height: 50, width: 50, '& img': {
                    objectFit: 'contain',
                  },
                }}
              />
            </div>
          )
        }
      },
    },
    {
      field: 'description',
      headerName: 'QUESTION',
      width: 230,
    },
    {
      field: 'categoryId',
      headerName: 'CATEGORY',
      width: 150,
      type: 'singleSelect',
      valueOptions: categories.map((category: any) => ({
        value: category.categoryId.toString(),
        label: category.name,
      })),
      renderCell: (params: any) => params.value,
      /* valueGetter: (params: any) =>  params.row.cId,
      valueFormatter: (params: any) => {
        const selectedCategory = categories.find(
          (category: any) => category.categoryId === params.value
        )
        if (!selectedCategory) {
          return  // Beklenmeyen bir değeri loglayın
        }
        return selectedCategory ? selectedCategory.name.replace(/●/g, '') : ''
      }, */
    },

    {
      field: 'createdDate',
      headerName: 'CREATED DATE',
      width: 180,
      type: 'dateTime',
      valueGetter: ({ value }: { value: Date }) => value && new Date(value),
    },
    { field: 'editor', headerName: 'EDITOR', width: 150 },
    { field: 'creator', headerName: 'CREATOR', width: 150 },
    {
      field: 'statusId',
      headerName: 'STATUS',
      width: 150,
      type: 'singleSelect',
      filterable: true,
      valueOptions: status.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      renderCell: (params: GridCellParams) => <StatusCell value={params.value as number} />,
      /*    valueFormatter: (params: any) => {
          const selectedStatus = status.find((status: any) => status.value === params.value);
          if (!selectedStatus) {
            console.log('Unexpected value:', params.value);
            return null;
          }
          return selectedStatus ? <StatusCell value={params.value as number}/> : null;
        }, */
    },
  ]

  const [isCheckAll, setIsCheckAll] = useState(false)
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [first, setfirst] = useState(true)


  const [selectCategory, setSelectCategory] = useState<number>(0)
  const [showEdit, setshowEdit] = useState(false)
  const [hover, sethover] = useState('')
  const [isQuestionCreatedId, setisQuestionCreatedId] = useState(0)
  const [dublicateId, setDublicateId] = useState<number | null>(null)

  useEffect(() => {
    if (dublicateId) {
      setSelectQuestionEdit([dublicateId])
      setshowEdit(true)
      setDublicateId(null)
    }
  }, [dublicateId])


  /*   useEffect(() => {
      if (isQuestionCreatedId !== 0) {
        setshowEdit(true)
        setselectedQuestion(isQuestionCreatedId)
      }
    }, [isQuestionCreatedId]) */
  useEffect(() => { }, [selected, isCheckAll])

  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState<any>(`page=${page}&size=10&`)
  const LoginState = useSelector((state: any) => state.Login)

  const [user, setuser] = useState<any>('')
  const [New, setNew] = useState(false)

  const [showNew, setshowNew] = useState(false)
  useEffect(() => {
    if (New == true) {
      setshowNew(true)
      setTimeout(() => {
        setNew(false)
        setshowNew(false)
      }, 1000)
    }
    
  }, [New])

  const [filterOptions, setFilterOptions] = useState<any>({
    question: '',
    category: '',
    questionId: '',
    CreatedDateStart: '',
    CreatedDateEnd: '',
    CreatedBy: '',
    Status: '',
  })
  const [sortOptions, setSortOptions] = useState<any>('')

  /*   useEffect(() => {
      setTimeout(() => {
        if (localStorage.getItem('modal') != undefined) {
          if (localStorage.getItem('modal') != '-1') {
            setselectedQuestion(localStorage.getItem('modal'))
            setshowEdit(true)
          }
        }
        localStorage.setItem('modal', '-1')
      }, 2000)
    }, []) */

  useEffect(() => { }, [LoginState.loginData])
  const [isLoading, setisLoading] = useState(false)
  useEffect(() => {
    async function test2() {
      let customerId = customerProfile.customerId
      setuser(customerId)
      /*   await axios
        .get(`${process.env.REACT_APP_API_URL}/user/getUserWithJWT`, {
          headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
        })
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setuser(response.data.customerId)
        }) */
    }
    test2()
  }, [customerProfile])
  useEffect(() => {
    if (editQuestion) {
      setSelectQuestionEdit([parseInt(editQuestion)])
      setshowEdit(true)
    }
  }, [editQuestion])
  useEffect(() => {
    setPagination(`page=${page}&size=10&`)
    setisLoading(true)
  }, [pagination, page, user, filterOptions, sortOptions, first])

  const StatusCell = ({ value }: { value: number }) => {
    const getStatusComponent = (status: number) => {
      if (status === 0) {
        return (
          <Chip
            className='overflow-visible'
            label='Blocked'
            variant='outlined'
            color='secondary'
            size='small'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 1) {
        return (
          <Chip
            className='overflow-visible'
            label='Open'
            variant='outlined'
            color='success'
            size='small'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 2) {
        return (
          <Chip
            className='overflow-visible'
            label='Deleted'
            variant='outlined'
            color='error'
            size='small'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      return null
    }

    return <div style={{ height: '19px', width: '57px' }}>{getStatusComponent(value)}</div>
  }

  const [showStatu, setShowStatu] = useState(false)
  const handleClose = () => setShowStatu(false)
  const [newStatusId, setnewStatusId] = useState<any>('')

  /*  useEffect(() => {
     if (DublicateId !== -1) {
       setshowEdit(true)
       setselectedQuestion(DublicateId)
     }
     setTimeout(() => {
       setDublicateId(-1)
     }, 1000)
   }, [DublicateId]) */
  //#region dataGridPro
  //serverside pagination start
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [selectQuestionEdit, setSelectQuestionEdit] = useState([])
  const [selectQuestion, setSelectQuestion] = useState([])
  const [questionStatus, setQuestionStatus] = useState(-1)
  const [queryOptions, setQueryOptions] = useState<any>({
    sortModel: [
      { field: 'statusId', sort: 'asc' },
      { field: 'createdDate', sort: 'desc' },
    ],
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    /*    id: false,
       brokerId: false,
       status: false, */
  })

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
      return
    } */

    setQueryOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])

  const fetchRows = async () => {
    setIsLoadingData(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/content/getAllCategoriesForQuestionList`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setCategories(res.data)
      })
      .catch((err) => { })

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/content/getQuestionListByCustomerId?&page=${paginationModel.page + 1
        }&size=${paginationModel.pageSize}`,
        {
          queryOptions,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const formattedRows = response.data.items?.map((row: any) => ({
          id: row.questionId,
          mediaUrl: row.mediaUrl,
          mediaType: row.mediaType,
          description: row.description,
          categoryId: row.categoryId,
          cId: row.cId,
          questionId: row.questionId,
          createdDate: row.createdDate,
          editor: row.editor,
          statusId: row.statusId,
          source: row.source,
          creator: row.creator,
        }))

        setRows(formattedRows || [])
        setRowCount(response.data.total)
        setIsLoadingData(false)
        setisLoading(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
        setisLoading(false)
      })
  }
  const [loadingExport, setLoadingExport] = useState(false)
  async function downloadExcel() {
    setLoadingExport(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/content/getQuestionListByCustomerId?isExcel=1`,
        {
          queryOptions,
          columnVisibilityModel,
        },
        {
          responseType: 'blob',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const excelBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const excelUrl = URL.createObjectURL(excelBlob)

        const link = document.createElement('a')
        link.href = excelUrl
        link.download = 'questionList.xlsx'
        link.click()
        URL.revokeObjectURL(excelUrl)
        setLoadingExport(false)
      })
      .catch((err) => {
        setLoadingExport(false)
      })
    /*     const newData = rows.map((row: any) => {
      delete row.tableData
      return row
    })
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, 'row')
    //Buffer
    XLSX.write(workBook, {bookType: 'xlsx', type: 'buffer'})
    //Binary string
    XLSX.write(workBook, {bookType: 'xlsx', type: 'binary'})
    //Download
    XLSX.writeFile(workBook, 'Veri Listesi.xlsx') */
  }

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ ml: 1, mr: 1 }}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelectQuestion(newSelectionModel)
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <LoadingButton
          size='small'
          onClick={downloadExcel}
          endIcon={<FileDownloadIcon />}
          loading={loadingExport}
          loadingPosition='end'
          variant='outlined'
        >
          <span>Export</span>
        </LoadingButton>
      </GridToolbarContainer>
    )
  }
  useEffect(() => {
    fetchRows()
  }, [paginationModel, first, queryOptions])



  const handleCellOneClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }
    const newSelectionModel = [...selectQuestion];
    const selectionIndex = newSelectionModel.indexOf(params.id);

    if (selectionIndex === -1) {
      // Eğer tıklanan hücrenin ID'si şu anda seçili değilse, seçime ekleyin
      newSelectionModel.push(params.id);
    } else {
      // Eğer tıklanan hücrenin ID'si zaten seçiliyse, seçimden kaldırın
      newSelectionModel.splice(selectionIndex, 1);
    }
    setSelectQuestion(newSelectionModel)
  }

  //#endregion

  //speed dial start
  const [speedDialOpen, setSpeedDialOpen] = useState(false)

  const handleCloseSpeedDial = () => {
    setSpeedDialOpen(false)
  }
  const handleOpenSpeedDial = () => {
    setSpeedDialOpen(true)
  }

  const actions = [
    { icon: <CheckIcon style={{ color: 'black' }} />, name: 'Open', value: 1 },
    /*     { icon: <BlockIcon style={{ color: 'black' }} />, name: 'Block', value: 0 }, */
    { icon: <DeleteIcon style={{ color: 'black' }} />, name: 'Delete', value: 2 },
  ]

  //speed dial end

  //question Category Change


  //question Status Change
  useEffect(() => {
    if (questionStatus === -1) return
    else if (questionStatus === 1) {
      handleStatusChange()
    } else {
      handleClickWarnModalOpen()
    }
  }, [questionStatus])

  async function handleStatusChange() {
    selectQuestion.length > 0 &&
      questionStatus !== -1 &&
      (await axios
        .post(
          `${process.env.REACT_APP_API_URL}/content/questionsChangeStatus`,
          { questionId: selectQuestion, questionStatus: questionStatus },
          {
            headers: {
              accept: 'application/json',
              Authorization: `Bearer ${LoginState.loginData.access_token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          let message

          switch (questionStatus) {
            case 1:
              message = `${selectQuestion.length}  records has been changed OPEN status successfully`
              break
            case 0:
              message = `${selectQuestion.length} records has been changed BLOCK status successfully`
              break
            case 2:
              message = `${selectQuestion.length}  records has been changed DELETE status successfully`
              break
            default:
              message = `${selectQuestion.length}  records has been changed to the specified status successfully`
              break
          }
          enqueueSnackbar(message, { variant: 'success' })
          setSelectQuestion([])
          handleClickWarnModalClose()
        })
        .catch((err) => {
          let errorMessage

          switch (questionStatus) {
            case 1:
              errorMessage = `${selectQuestion.length} records could not be changed to OPEN status.`
              break
            case 2:
              errorMessage = `${selectQuestion.length} records could not be changed to CLOSED status.`
              break
            case 3:
              errorMessage = `${selectQuestion.length} records could not be changed to WAITING status.`
              break
            default:
              errorMessage = `${selectQuestion.length} records could not be changed to the specified status.`
              break
          }

          enqueueSnackbar(errorMessage, { variant: 'error' })
        }))
    setQuestionStatus(-1)
    setfirst(!first)
  }
  const [hoverUploadButton, setHoverUploadButton] = useState(false)
  const [openUploadModal, setOpenUploadModal] = useState(false)
  //duplicate question
  async function duplicateQuestion() {
    selectQuestion.length === 1 &&
      (await axios
        .post(`${process.env.REACT_APP_API_URL}/content/question/duplicate/${selectQuestion}`, '', {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          enqueueSnackbar('Successfully saved', { variant: 'success' })
          setfirst(!first)
        })
        .catch((err) => {
          err.response?.data?.detail.errorMessage
            ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
            : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              { variant: 'error' }
            )
        }))
  }
  const [openQuestionAi, setOpenQuestionAi] = useState(false)
  const [hoverButton, setHoverButton] = useState({
    ai: false,
    edit: false,
    duplicate: false,
    changeCategory: false,
  })


  return (
    <>
      <div className='border-0'>
        {/* begin::Card toolbar */}
        <div className='w-50 float-end'>
          <div
            style={{ marginTop: '-62px', marginRight: 80 }}
          /*  className='d-flex justify-content-end align-items-center' */
          /*   data-kt-user-table-toolbar='base' */
          >
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              alignContent='center'
              sx={{ height: 50 }}
            >
              <div>
                {
                  roleIsValid &&
                  <Tooltip
                    arrow
                    title='Ai-powered question generator'
                    placement='top'
                    onMouseEnter={() => {
                      setHoverButton((buttons) => ({ ...buttons, ai: true }))
                    }}
                    onMouseLeave={() => {
                      setHoverButton((buttons) => ({ ...buttons, ai: false }))
                    }}
                    style={{ background: hoverButton.ai ? '#D5D5D5 ' : 'white' }}
                  >
                    <IconButton
                      aria-label='delete'
                      onClick={() => setOpenQuestionAi(true)}
                      size='small'
                      sx={{ background: 'white', marginRight: '7px' }}
                    >
                      <AddIcon sx={{ fontSize: '30px', color: hoverButton.ai ? 'black' : ' ' }} />
                      <div style={{ marginBottom: '-36px', marginLeft: '-18px' }}>
                        <img src={'/media/icons/ai.png'} height={20} width={20} />
                      </div>
                    </IconButton>
                  </Tooltip>
                }

              </div>
              {
                roleIsValid &&
                <AddQuestionModal
                  showNew={showNew}
                  company={user}
                  setfirst={setfirst}
                  first={first}
                  isQuestionCreatedId={setisQuestionCreatedId}
                />
              }


              {roleIsValid && selectQuestion.length === 1 && (
                <Tooltip
                  arrow
                  title='Edit'
                  placement='top'
                  onMouseEnter={() => {
                    setHoverButton((buttons) => ({ ...buttons, edit: true }))
                  }}
                  onMouseLeave={() => {
                    setHoverButton((buttons) => ({ ...buttons, edit: false }))
                  }}
                  style={{ background: hoverButton.edit ? '#D5D5D5 ' : 'white' }}
                >
                  <IconButton
                    onClick={() => {
                      if (selectQuestion.length === 1) {
                        setSelectQuestionEdit(selectQuestion[0])
                        setshowEdit(true)
                      }
                    }}
                    size='small'
                    sx={{ background: 'white' /* marginRight: '7px' */ }}
                  >
                    <EditIcon sx={{ fontSize: '30px', color: hoverButton.edit ? 'black' : '' }} />
                  </IconButton>
                </Tooltip>
              )}
              {roleIsValid && selectQuestion.length > 0 && (
                <Tooltip arrow title='Change Status' placement='top'>
                  <SpeedDial
                    ariaLabel='SpeedDial openIcon example'
                    style={{ transform: 'scale(0.73)', marginTop: '93px' }}
                    icon={
                      speedDialOpen ? (
                        <ExpandMoreIcon fontSize='large' />
                      ) : (
                        <SettingsIcon fontSize='large' />
                      )
                    }
                    openIcon={<DeleteIcon fontSize='large' />}
                    onOpen={handleOpenSpeedDial}
                    onClose={handleCloseSpeedDial}
                    FabProps={{ color: 'inherit' }}
                    open={speedDialOpen}
                    direction='down'
                  >
                    {actions.map((action) => (
                      <SpeedDialAction
                        /*  style={{transform: 'scale(0.70)'}} */
                        delay={1000}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => setQuestionStatus(action.value)}
                      />
                    ))}
                  </SpeedDial>
                </Tooltip>
              )}

              {/* Diğer bileşenler */}
              {/* <FilterTableQuestion setCategoryName={setFilterOptions} customerId={user} /> */}
              {
                roleIsValid &&
                <Tooltip
                  arrow
                  title='Upload'
                  placement='top'
                  onMouseEnter={() => {
                    setHoverUploadButton(true)
                  }}
                  onMouseLeave={() => {
                    setHoverUploadButton(false)
                  }}
                  style={{ background: hoverUploadButton ? '#D5D5D5 ' : 'white' }}
                >
                  <IconButton
                    aria-label='delete'
                    onClick={() => setOpenUploadModal(true)}
                    size='small'
                    sx={{ background: 'white', color: hoverButton ? 'black' : ' ' }}
                  >
                    <UploadOutlinedIcon sx={{ fontSize: '30px', color: "#00000099" }} />
                  </IconButton>
                </Tooltip>
              }
              {
                roleIsValid &&
                <ImportQuestionModal
                  open={openUploadModal}
                  setOpen={setOpenUploadModal}
                  first={first}
                  setFirst={setfirst}
                />
              }

              {/* <WizardAddQuestion customer={user} setsecond={setfirst} second={first} /> */}
              {selectQuestion.length === 1 && (
                <Tooltip
                  arrow
                  title='Duplicate'
                  placement='top'
                  onMouseEnter={() => {
                    setHoverButton((buttons) => ({ ...buttons, duplicate: true }))
                  }}
                  onMouseLeave={() => {
                    setHoverButton((buttons) => ({ ...buttons, duplicate: false }))
                  }}
                  style={{ background: hoverButton.duplicate ? '#D5D5D5 ' : 'white' }}
                >
                  <IconButton
                    aria-label='Duplicate'
                    onClick={() => {
                      duplicateQuestion()
                    }}
                    size='small'
                    sx={{
                      background: 'white',
                      color: hoverButton.duplicate ? 'black' : '',
                      marginRight: '7px',
                      marginLeft: '7px',
                    }}
                  >
                    <ContentCopyOutlinedIcon sx={{ fontSize: '30px' }} />
                  </IconButton>
                </Tooltip>
              )}
              {selectQuestion.length > 0 && (
                <Tooltip
                  arrow
                  title='Change Category'
                  placement='top'
                  onMouseEnter={() => {
                    setHoverButton((buttons) => ({ ...buttons, changeCategory: true }))
                  }}
                  onMouseLeave={() => {
                    setHoverButton((buttons) => ({ ...buttons, changeCategory: false }))
                  }}
                  style={{ background: hoverButton.changeCategory ? '#D5D5D5 ' : 'white' }}
                >
                  <IconButton
                    aria-label='Change Category'
                    onClick={() => {
                      setOpenCategoryModal(true)
                    }}
                    size='small'
                    sx={{
                      background: 'white',
                      color: hoverButton.changeCategory ? 'black' : '',
                      marginLeft: '7px',
                    }}
                  >
                    <DriveFileMoveOutlinedIcon sx={{ fontSize: '30px' }} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </div>
        </div>
      </div>

      <div className='card card-custom p-3  '>
        <Box sx={{ height: 600, width: '100%' }}>
          <StyledDataGrid
            /*   {...data}
                loading={data.rows.length === 0} */
            rows={rows || []}
            columns={columns}
            rowHeight={50}
            columnHeaderHeight={40}
            disableVirtualization
            checkboxSelection
            onCellClick={handleCellOneClick}
            getRowId={(row) => row.id}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            disableRowSelectionOnClick
            sortingMode='server'
            onSortModelChange={handleSortModelChange}
            sortModel={queryOptions?.sortModel}
            onRowSelectionModelChange={handleSelectionModelChange}
            rowSelectionModel={selectQuestion}
            filterMode='server'
            onFilterModelChange={onFilterChange}
            isRowSelectable={(params: GridRowParams) => params.row.source === 'Client'}
            rowCount={rowCount}
            loading={isLoadingData}
            paginationModel={paginationModel}
            paginationMode='server'
            pagination={true}
            onPaginationModelChange={setPaginationModel}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
            }
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
            sx={{
              boxShadow: "none",
              border: "none",
              '& .MuiDataGrid-footerContainer': {
                padding: '0px',
                height: 30
              },

              '& .MuiDataGrid-cell:hover': {
                /*    color: 'primary.main', */
                cursor: 'pointer',
              },
              '&	.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                color: '#A1A5B7',
                fontSize: 12,
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
              },
            }}
          />
        </Box>
      </div>
      {
        editQuestion &&
        <EditQuestionModal
          setNew={setNew}
          setDublicateId={setDublicateId}
          company={user}
          setfirst={setfirst}
          first={first}
          questionId={selectQuestionEdit}
          setShow={setshowEdit}
          show={showEdit}
        />
      }
      {selectQuestion.length > 0 ? (
        <EditQuestionModal
          setNew={setNew}
          setDublicateId={setDublicateId}
          company={user}
          setfirst={setfirst}
          first={first}
          questionId={selectQuestionEdit}
          setShow={setshowEdit}
          show={showEdit}
        />
      ) : null}
      <SelectCategory
        openCategoryModal={openCategoryModal}
        setOpenCategoryModal={setOpenCategoryModal}
        selectQuestion={selectQuestion}
        setSelectQuestion={setSelectQuestion}
        company={user}
        second={first}
        setSecond={setfirst}
      />

      {
        //warn modal
      }
      <Dialog
        open={openWarnModal}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Typography color='white'>
              {
                questionStatus === 0 ? "This operation will BLOCK selected records. Blocked records can be seen in the historical reports, lists and game results.You can unblock the record at any time.For extra security, you must confirm the process. Proceed?"
                  :
                  " This operation will DELETE selected records.Deleted records CAN’T be seen in the reports, lists, results etc.Deletion process can be undone by the MasterAdmin only.For extra security, you must confirm the process. Proceed?"
              }
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickWarnModalClose} style={{ color: 'white' }}>
            No
          </Button>
          <Button onClick={() => handleStatusChange()} style={{ color: 'white' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <AddQuestionAi
        open={openQuestionAi}
        setOpen={setOpenQuestionAi}
        first={first}
        setFirst={setfirst}
      />
    </>
  )
}

export default function Snackbar(/* {user, first, setfirst}: Props */ id: any) {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} maxSnack={3}>
      <QuestionsList id={id} /* {...{user, first, setfirst}} */ />
    </SnackbarProvider>
  )
}
