import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import JourneyTiming from './JourneyTiming'
import { forwardRef, useCallback, useEffect, useState } from 'react'
// import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slide,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material'
import Box from '@mui/material/Box'
import { DateRange } from '@mui/x-date-pickers-pro'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useLocation, useSearchParams } from 'react-router-dom'
import Award from './JourneyAwardModal'
import JourneyAddEditUserGroupModal from './JourneyAddEditUserGroupModal'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'
import { darken, lighten, styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from 'notistack'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import {
  DataGridPro,
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SelectContentNew from './SelectContentNew'
import { TransitionProps } from '@mui/material/transitions'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CloseIcon from '@mui/icons-material/Close'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import SaveIcon from '@mui/icons-material/Save';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';


const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}))


const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: /* theme.palette.common.white */"#a7a7a7",
    /*  color: 'black', */
    maxWidth: 500,
    boxShadow: theme.shadows[1],
  },
}));

function JourneyCreate() {
  const location: any = useLocation()
  /*   const [selected, setSelected] = useState<any>(initialListView.selected) */

  const [step, setStep] = useState(0)


  const [changed, setChanged] = useState(false)
  const [createJourneyId, setCreateJourneyId] = useState<any>(null)

  const steps = ['Activities', 'Timing', 'Participants', 'Award', 'Preview']

  const [activityList, setActivityList] = useState<any>([])
  const [cardName, setcardName] = useState('')
  /*   const [awardWinners, setAwardWinners] = useState<any>() */
  const [awardData, setAwardData] = useState<any>()
  const [hoverSelectedActivities, setHoverSelectedActivities] = useState<any>(-1)
  const [selectedCard, setselectedCard] = useState<any>()


  // participants
  const [assignmentType, setAssignmentType] = useState<any>(true)
  //timeModal
  const [timeStart, setTimeStart] = useState<any>(/* dayjs().startOf('day') */)
  const [timeEnd, setTimeEnd] = useState<any>(/* dayjs().endOf('day') */)

  const [dates, setDates] = useState<any>([dayjs(), dayjs().add(1, 'day')])
  const [dateTime, setDateTime] = useState<any>(null)
  const [dateTimeEnd, setDateTimeEnd] = useState<any>(null)
  useEffect(() => {
    if (step > 0 && journeyId) {
      handleGetJourney()
    }
  }, [step])

  //user-Group Modal
  const [assignmentTypeOff, setAssignmentTypeOff] = useState<any>([])
  const [userList, setUserList] = useState<any>([])
  const [groupList, setGroupList] = useState<any>([])
  const [selectedUserCount, setSelectedUserCount] = useState<any>(0)
  const [chatIsOn, setChatIsOn] = useState<any>(true)
  // award
  const [selectedAwardValue, setSelectedAwardValue] = useState<any>(0)

  const [awardIsTrue, setAwardIsTrue] = useState(false)
  //summary
  interface ISumData {
    numberOfActivities: number
    totalUser: number
    startTime?: string
    endTime?: string
    startDate?: string
    endDate?: string
    awardIsOn: boolean
    chatIsOn: boolean
    journeyUrl: string
    base64qr: string
  }
  const [sumData, setSumData] = useState<ISumData>({
    numberOfActivities: 0,
    totalUser: 0,
    startTime: '',
    endTime: '',
    startDate: '',
    endDate: '',
    awardIsOn: false,
    chatIsOn: false,
    journeyUrl: '',
    base64qr: '',
  })

  const LoginState = useSelector((state: any) => state.Login)

  const [editJourney, setEditJourney] = useState<any>(null)
  const [discardWarnModal, setDiscardWarnModal] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState('');
  const handleDownloadQRCode = () => {
    // QR kodunun indirilebilmesi için bir veri URL'si (Data URL) oluşturun.
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const qrImage: any = new Image();
    qrImage.src = sumData.base64qr; // qrCodeData, QR kodunun veri URL'si olmalıdır.

    qrImage.onload = () => {
      canvas.width = qrImage.width;
      canvas.height = qrImage.height;
      ctx.drawImage(qrImage, 0, 0);

      // Veri URL'sini oluşturun ve indirme bağlantısını ayarlayın.
      const dataUrl = canvas.toDataURL('image/png');
      setDownloadUrl(dataUrl);

      // Veriyi URL'ye dönüştürdükten sonra indirme işlemi başlatın.
      const a = document.createElement('a');
      a.href = dataUrl;
      a.download = `${cardName}_QRCode.png`; // İndirilen dosyanın adını ayarlayabilirsiniz.
      a.click();
    };
  };

  async function getActivitiesList() {
    setIsLoadingData(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/getActivitiesList?page=${paginationModel.page + 1
        }&size=${paginationModel.pageSize}&status=OPEN${makeJourneyActivity ? `&makeJourney=${makeJourneyActivity}` : ''
        }`,
        {
          activityOptions,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        const formattedRows = response.data.items.map((row: any) => ({
          activityId: row.activityId,
          title: row.title,
          activityTypeName: row.activityTypeName,
          activitySubTypeName: row.activitySubTypeName,
          categoryName: row.categoryName,
          timeLimit: row.timeLimit,
          statusId: row.statusId,
          createdDate: row.createdDate,
          createdName: row.createdName,
          icon: row.icon,
        }))
        setRows(formattedRows)
        setRowCount(response.data.total)
        setIsLoadingData(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
      })
  }





  function goUpper(selectedActivity: any) {
    setChanged(true)
    const index = activityList.findIndex(
      (activity: any) => activity.activityId === selectedActivity.activityId
    )
    if (index > 0) {
      const updatedActivityList = [...activityList]
      const selectedActivityCopy = { ...selectedActivity }

      // Seçili öğe ile bir üst sıradaki öğeyi yer değiştirin
      const temp = updatedActivityList[index - 1]
      updatedActivityList[index - 1] = selectedActivityCopy
      updatedActivityList[index] = temp

      // Sıralamayı güncelleyin
      updatedActivityList.forEach((item, index) => {
        item.sortOrder = index + 1
      })
      setActivityList(updatedActivityList)
    }
  }

  function goDown(selectedActivity: any) {
    setChanged(true)
    const index = activityList.findIndex(
      (activity: any) => activity.activityId === selectedActivity.activityId
    )

    if (index !== activityList?.length - 1) {
      const updatedActivityList = [...activityList]
      const selectedActivityCopy = { ...selectedActivity }

      // Seçili öğe ile bir üst sıradaki öğeyi yer değiştirin
      const temp = updatedActivityList[index + 1]
      updatedActivityList[index + 1] = selectedActivityCopy
      updatedActivityList[index] = temp

      // Sıralamayı güncelleyin
      updatedActivityList.forEach((item, index) => {
        item.sortOrder = index + 1
      })
      setActivityList(updatedActivityList)
    }
  }

  const navigate = useNavigate()
  const activityListed = activityList?.map((e: any) => {
    return {
      sortOrder: e.sortOrder,
      activityId: e.activityId,
    }
  })

  const [copied, setCopied] = useState(false)
  const [clickedSumSave, setClickedSumSave] = useState(false)
  const handleCopied = () => {
    navigator.clipboard.writeText(sumData.journeyUrl)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
    setClickedSumSave(true)
  }
  const [chatIsOnLoading, setChatIsOnLoading] = useState(false)
  const handleChangeChat = async (event: any) => {
    setChatIsOn(event.target.checked)
    setChatIsOnLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/editJourney/chat/${createJourneyId || journeyId}`,
        {
          chatIsOn: event.target.checked
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      ).then((response: any) => {
        //loading eklenecek
        setChatIsOnLoading(false)
        setChatIsOn(response?.data.chatIsOn)

      }).catch((err) => {
        setChatIsOnLoading(false)
        setResult(false)
        setShowSucces(true)
        setTimeout(() => {
          setShowSucces(false)
        }, 2000)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  // time

  const [startDateTime, setStartDateTime] = useState("")
  const [endDateTime, setEndDateTime] = useState("")

  function formatUTCDateTime(utcDateTime) {
    const date = new Date(utcDateTime);

    const formattedDate = date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC'
    });

    // "DD/MM/YYYY, HH:MM" formatından "DD.MM.YYYY HH:mm" formatına dönüştür
    return formattedDate.replace(/\//g, '.').replace(',', '');
  }

  useEffect(() => {

    if (dateTime && dateTimeEnd) {
      setStartDateTime(dateTime?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') || null)
      setEndDateTime(dateTimeEnd?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') || null)
    }
  }, [dateTimeEnd, dateTime])


  async function createQrCode() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Game/createQrCode`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((resp) => {
        setSumData((prevData) => ({
          ...prevData, // Önceki verileri koruyun
          base64qr: resp.data.base64qr, // Yeni verileri ekleyin
          journeyUrl: resp.data.journeyUrl,
        }))

        /* setClickedSumSave(true) */
        setStep(step + 1)
      })
      .catch((err) => {
        /*   console.log(err) */
      })
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const [journeyId, setJourneyId] = useState(searchParams.get('id'))

  useEffect(() => {
    if (journeyId) {
      handleGetJourney()
      setEditJourney(true)
      setJourneyFlowTypeDisabled(true)
    }
  }, [journeyId,])



  //#region status open & close elements

  const [journeyStatus, setJourneyStatus] = useState('')
  const [activities, setActivities] = useState(false)
  const [assigmentTypeChange, setAssigmentTypeChange] = useState(false)
  const [participantsUser, setParticipantsUser] = useState(false)
  const [participantsUser1, setParticipantsUser1] = useState(false)
  const [time, setTime] = useState(false)
  const [time1, setTime1] = useState(false)
  const [award, setAward] = useState(false)
  const [chatIsActive, setChatIsActive] = useState(false)
  // burada sorulması gereken soru şu açık mı değil mi ? false ise açık true ise kapalıdır.
  useEffect(() => {
    if (journeyStatus === 'PAUSED') {
      setActivities(true)
      setTime(true)
      setTime1(false)
      setParticipantsUser(false)
      setParticipantsUser1(false)
      setAssigmentTypeChange(true)
      setChatIsActive(false)
      setAward(false)
    } else if (journeyStatus === 'STARTED') {
      setActivities(true)
      setTime(true)
      setTime1(false)
      setParticipantsUser(false)
      setParticipantsUser1(false)
      setAssigmentTypeChange(true)
      setChatIsActive(false)
      setAward(false)
    } else if (journeyStatus === 'FINISHED') {
      setActivities(true)
      setTime(true)
      setTime1(true)
      setParticipantsUser(true)
      setParticipantsUser1(false)
      setAssigmentTypeChange(true)
      setChatIsActive(false)
      setAward(true)
    } else if (journeyStatus === 'DELETED') {
      setActivities(true)
      setTime(true)
      setTime1(true)
      setParticipantsUser(true)
      setParticipantsUser1(true)
      setAssigmentTypeChange(true)
      setChatIsActive(true)
      setAward(true)
    } else if (journeyStatus === 'STOPPED') {
      setActivities(true)
      setTime(true)
      setTime1(true)
      setParticipantsUser(true)
      setParticipantsUser1(false)
      setAssigmentTypeChange(true)
      setChatIsActive(true)
      setAward(true)
    } else {
      setActivities(false)
      setParticipantsUser(false)
      setParticipantsUser1(false)
      setTime(false)
      setTime1(false)
      setAward(false)
      setAssigmentTypeChange(false)
      setAward(false)
    }
  }, [journeyStatus])

  //#endregion
  const [privateAssignmentType, setPrivateAssignmentType] = useState(false)
  async function handleGetJourney(newJourneyId?) {
    setJourneyCreateLoding(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Game/getJourneyWithJourneyId/${journeyId || createJourneyId || newJourneyId}`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((resp) => {
        setJourneyCreateLoding(false)
        setJourneyStatus(resp.data.journeyStatus)
        setDateTime(resp.data.startDateTime ? dayjs(resp.data.startDateTime) : null)
        setDateTimeEnd(resp.data.endDateTime ? dayjs(resp.data.endDateTime) : null)
        setcardName(resp.data.journeyName)
        setAssignmentType(resp.data.assignmentType == 1 ? true : false)
        setPrivateAssignmentType(resp.data.privateAssignmentType === 0 ? true : false)
        setChatIsOn(resp.data.chatIsOn)
        setJourneyFlowType(resp.data.flowType)
        const activityIds = resp.data.activityList?.map((item: any) => item?.activityId)
        setSelected(activityIds)
        let activityList = resp.data.activityList
        let selectedSortedData = activityList?.sort((a: any, b: any) => {
          return a.sortOrder - b.sortOrder
        })
        setActivityList(selectedSortedData)
        /* resp.data.activityList?.map((item:any) => {
          setSelected((prevSelected:any) => [...prevSelected, item?.activityId]);
        }) */
        // award
        setSelectedAwardValue(parseInt(resp.data.awardWinners))
        setAwardData({
          ...awardData,
          awardMediaType: resp.data?.journeyAwardMediaType,
          awardMediaUrl: resp.data?.journeyAwardMediaUrl,
          awardMediaText: resp.data?.journeyAwardMediaText,
        })
        //summary
        setSumData({
          numberOfActivities: resp.data.numberOfActivities,
          totalUser: resp.data.totalUser,
          awardIsOn: resp.data.awardIsOn,
          chatIsOn: resp.data.chatIsOn,
          base64qr: resp.data.base64qr,
          journeyUrl: resp.data.journeyUrl,
        })

      }).catch((err) => {
        setJourneyCreateLoding(false)
      })
  }
  const handleJourneyList = () => {
    navigate('/journey')
  }
  const [awardLoading, setAwardLoading] = useState(false)
  const awardFunction = async () => {

    const form = new FormData()
    if (awardIsTrue === false) {
      form.append('awardMediaType', '0')
      form.append('awardMediaUrl', '')
      form.append('awardMediaText', '')
      form.append('awardWinners', selectedAwardValue || 0)
    } else {
      awardData.file && form.append('file', awardData.file)
      form.append('awardMediaType', awardData.awardMediaType || '0')
      form.append('awardMediaUrl', awardData.awardMediaUrl || '')
      form.append('awardMediaText', awardData.awardMediaText || '')
      form.append('awardWinners', selectedAwardValue || 0)
    }
    setAwardLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/Game/addAwardToJourney/${journeyId || createJourneyId}`, form, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setSumData((prevData) => ({
          ...prevData, // Önceki verileri koruyun
          base64qr: res.data?.qrCode.base64qr, // Yeni verileri ekleyin
          journeyUrl: res.data?.qrCode.journeyUrl,
        }))
        setSelectedUserCount(res.data?.userCounts)
        setAwardLoading(false)
        /*   setResult(true)
          setShowSucces(true)
          setTimeout(() => {
            setShowSucces(false)
          }, 2000) */

      })
      .catch((err) => {
        setAwardLoading(false)
        /*   setResult(false)
          setShowSucces(true)
          setTimeout(() => {
            setShowSucces(false)
          }, 2000) */
        /*  console.log(err) */
      })
  }

  const [assignmentTypeLoading, setAssignmentTypeLoading] = useState(false)

  const handleChangeAssigmentType = async (assignmentType) => {

    setAssignmentTypeLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/editJourney/assignmentType/${createJourneyId || journeyId}`,
        {
          assignmentType: assignmentType ? 1 : 0,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      ).then((response: any) => {
        //loading eklenecek
        setAssignmentTypeLoading(false)
        setAssignmentType(response?.data.assignmentType === 1 ? true : false)

      }).catch((err) => {
        setAssignmentTypeLoading(false)
        setResult(false)
        setShowSucces(true)
        setTimeout(() => {
          setShowSucces(false)
        }, 2000)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }
  const editFunc = async () => {

    const postData = {
      ...(step === 0 ? { name: cardName, activityList: activityListed } :
        {
          startDate: startDateTime ? startDateTime : null,
          endDate: endDateTime ? endDateTime : null,
        }
      )
      /*       chatIsOn: chatIsOn,
            awardIsOn: selectedAwardValue !== 0,
            awardWinners: selectedAwardValue,
            assignmentType: assignmentType ? 1 : 0,
            userIdList: assignmentType ? userListId : [],
            groupIdList: assignmentType ? groupListId : [],
            journeyurl: sumData.journeyUrl, */
    };

    setJourneyCreateLoding(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/editJourney/${step === 0 ? "activity" : "time"}/${createJourneyId || journeyId}`,
        {
          ...postData
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      ).then((response) => {
        setJourneyCreateLoding(false)
        if (step === 4) {
          setIsSaved(true);
        }
      }).catch((err) => {

        setJourneyCreateLoding(false)
        setResult(false)
        setShowSucces(true)
        if (step === 0) {
          setStep(0)
        }
        if (step === 1) {
          setStep(1)
        }
        setChanged(true)
        setTimeout(() => {
          setShowSucces(false)
        }, 2000)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  const [journeyCreateLoding, setJourneyCreateLoding] = useState(false)
  const [journeyFlowTypeDisabled, setJourneyFlowTypeDisabled] = useState(false)
  const [journeyFlowType, setJourneyFlowType] = useState(true)

  const [isSaved, setIsSaved] = useState(false);

  const saveFunc = async () => {
    //award



    setJourneyCreateLoding(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/createJourney`,
        {
          name: cardName,
          /*           startDate: startDateTime ? startDateTime : null,
                    endDate: endDateTime ? endDateTime : null,
                    chatIsOn: chatIsOn,
                    awardIsOn: selectedAwardValue === 0 ? false : true,
                    awardWinners: selectedAwardValue,
                    assignmentType: assignmentType ? 1 : 0, */
          activityList: activityListed,
          journeyFlowType: journeyFlowType
          /*           userIdList: assignmentType ? userListId : [],
                    groupIdList: assignmentType ? groupListId : [],
                    journeyurl: sumData.journeyUrl, */
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response: any) => {
        setJourneyCreateLoding(false)
        setCreateJourneyId(response?.data.newJourneyId)
        handleGetJourney(response?.data.newJourneyId)
      })
      .catch((err) => {
        setJourneyCreateLoding(false)
        setResult(false)
        setShowSucces(true)
        setStep(0)
        setTimeout(() => {
          setShowSucces(false)
        }, 2000)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
    /*  } */
  }

  const [showSucces, setShowSucces] = useState(false)
  const [result, setResult] = useState<any>()
  const [handleGroupLoading, setHandleGroupLoading] = useState(false)

  const handleCloseSucces = () => {
    setShowSucces(false)
  }
  const intl = useIntl()



  //#region DataGrid Start
  //States
  const [rows, setRows] = useState<any>([])
  const [rowCount, setRowCount] = useState(0)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [activityOptions, setActivityOptions] = useState<any>({ sortModel: [{ field: "createdDate", sort: "desc" }] })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    InternalEmployeeId: false,
  })
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 1000,
  })
  const [selected, setSelected] = useState<any>([])
  const [categoriesList, setCategoriesList] = useState<any>([])
  const [typesList, setTypesList] = useState<any>([])
  const [subTypesList, setSubTypesList] = useState<any>([])

  const columns: any = [
    {
      field: 'title',
      headerName: ' ACTIVITY NAME',
      width: 160,
      renderCell: (params: GridCellParams) => (
        <>
          <Avatar src={params.row.icon} style={{ marginRight: '10px' }} />
          {params.value}
        </>
      ),
    },
    {
      field: 'activityTypeName',
      headerName: 'TYPE',
      width: 100,
      type: 'singleSelect',
      valueOptions: typesList.map((option: any) => ({
        value: option.activityTypeId,
        label: option.activityTypeName,
      })),
      renderCell: (params: any) => params.value,
    },
    {
      field: 'activitySubTypeName',
      headerName: 'SUBTYPE',
      width: 100,
      type: 'singleSelect',
      valueOptions: subTypesList.map((option: any) => ({
        value: option.activitySubTypeId,
        label: option.activitySubTypeName,
      })),
      renderCell: (params: any) => params.value,
    },
    {
      field: 'categoryName',
      headerName: 'CATEGORY',
      width: 150,
      valueOptions: categoriesList.map((category: any) => ({
        value: category.categoryId,
        label: category.name,
      })),
      renderCell: (params: any) => params.value,
      type: 'singleSelect',
    },
    {
      field: 'timeLimit',
      headerName: 'TIME LIMIT',
      width: 100,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'createdDate',
      headerName: 'CREATED DATE',
      width: 170,
      type: 'dateTime',
      valueGetter: ({ value }: { value: Date }) => value && new Date(value),
    },
  ]

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setActivityOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    setActivityOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelected(newSelectionModel)
  }

  const [makeJourneyActivity, setMakeJourneyActivity] = useState<any>(
    searchParams.get('selectedActivity')
  )
  useEffect(() => {
    if (makeJourneyActivity && editJourney === false) {
      const activityIds = makeJourneyActivity?.split(',').map(Number)
      setSelected(activityIds)
    }
  }, [])

  useEffect(() => {
    if (makeJourneyActivity && editJourney === false) {
      let selectedData = async () =>
        await rows.filter((row: any) => selected.includes(row.activityId))

      setActivityList(selectedData)
    }
    if (editJourney === null || editJourney === true) {
      if (rows.length > 0) {
        const sortedSelected = selected.map((selectedId: any) => {
          return rows.find((item: any) => item.activityId === selectedId)
        })
        const sortedselectedWithSortOrder = sortedSelected.map((item: any, index: number) => ({
          ...item,
          sortOrder: index + 1,
        }))

        setActivityList(sortedselectedWithSortOrder)
      }
    }
  }, [selected])

  /*  useEffect(() => {
  if(rows.length < 1 && selected.length > 0){
    getActivitiesList()
  }
  }, [rows]) */

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    /* setOpenEditUserModal(true) */
    /*  userData.filter((user: any) => user.userId === params.row.userId)
      setSelectedUserData(userData.filter((user: any) => user.userId === params.row.userId)) */
    /*  navigate(`/manage/users/detail?id=${params.id}`) */
    // Diğer işlemleri burada yapabilirsiniz
  }
  const handleCellOneClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    /*     if (activities === false) {
      return
    } */
    if (activities) {
      return
    }
    setChanged(true)
    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }
    const newSelectionModel = [...selected]; // Mevcut seçili satırların bir kopyasını oluşturun
    const selectionIndex = newSelectionModel.indexOf(params.id);

    if (selectionIndex === -1) {
      // Eğer tıklanan hücrenin ID'si şu anda seçili değilse, seçime ekleyin
      newSelectionModel.push(params.id);
    } else {
      // Eğer tıklanan hücrenin ID'si zaten seçiliyse, seçimden kaldırın
      newSelectionModel.splice(selectionIndex, 1);
    }

    setSelected(newSelectionModel);

  }

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={1}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ ml: 1, mr: 1 }}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }

  useEffect(() => {
    getActivitiesList()
  }, [activityOptions, paginationModel, columnVisibilityModel])

  useEffect(() => {
    if (categoriesList.length === 0) {
      getAllCategories()
    }
  }, [categoriesList])

  useEffect(() => {
    if (typesList.length < 1 || subTypesList.length < 1) {
      getActivityTypes()
    }
  }, [])

  async function getAllCategories() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/content/getAllCategoriesForQuestionList`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setCategoriesList(res.data)

      })
      .catch((err) => { })
  }
  async function getActivityTypes() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Game/getActivityTypes`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setTypesList(res.data.activityTypeList)
        setSubTypesList(res.data.activitySubTypeList)
      })
      .catch((err) => { })
  }

  //#endregion
  const [hoverButton, setHoverButton] = useState(false)
  return (
    <>
      {
        //success modal
      }
      <Modal
        show={showSucces}
        onHide={handleCloseSucces}
        aria-labelledby='contained-modal-title-vcenter '
        centered
        size='lg'
      >
        <Modal.Header
          closeButton
          className={result ? 'bg-light-success border-0' : 'bg-light-danger border-0'}
        ></Modal.Header>
        <Modal.Body
          className={result ? 'bg-light-success text-success' : 'bg-light-danger text-danger'}
        >
          {' '}
          <div className='row text-center'>
            <div className='col-1'>
              <span
                className={
                  result
                    ? 'svg-icon svg-icon-4hx svg-icon-success me-3 '
                    : 'svg-icon svg-icon-4hx svg-icon-danger me-3 '
                }
              >
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='mh-50px'
                >
                  <path
                    opacity='0.3'
                    d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                    fill='currentColor'
                  ></path>
                  <path
                    d='M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z'
                    fill='currentColor'
                  ></path>
                </svg>
              </span>
            </div>

            <div className='text-center col-10'>
              {result
                ? intl.formatMessage({ id: 'JOURNEY.SUCCESS' })
                : intl.formatMessage({ id: 'JOURNEY.FAILED' })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          className={result ? 'bg-light-success  border-0 ' : 'bg-light-danger  border-0'}
        ></Modal.Footer>
      </Modal>

      <div>
        <div
          style={{
            display: 'flex',
            height: 40,
            width: '80%',
            marginBottom: '18px',
            marginTop: '-12px',
            cursor: 'default',
            pointerEvents: 'none',
            userSelect: 'none',
          }}
        >
          <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
            Manage - {editJourney ? 'EditJourney' : 'JourneyWizard'}
          </Typography>
        </div>
        {/*   <PageTitle breadcrumbs={VideoBreadcrumbs}>
          
        </PageTitle> */}
        {/* header */}

        {/* body */}
        <div className='row d-flex flex-row-reverse'>
          <Box
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            alignContent='center'
          /*     sx={{ height: 50 }} */
          >
            <div style={{ marginTop: '-70px', marginRight: 90 }}>
              {
                <Tooltip
                  arrow
                  title='Journey List'
                  placement='top'
                  onMouseEnter={() => {
                    setHoverButton(true)
                  }}
                  onMouseLeave={() => {
                    setHoverButton(false)
                  }}
                  style={{ background: hoverButton ? '#D5D5D5 ' : 'white' }}
                >
                  <IconButton
                    aria-label='By Activity'
                    onClick={() => {
                      navigate('/journey');
                    }}
                    size='small'
                    sx={{
                      background: 'white',
                      color: hoverButton ? 'black' : '',
                      marginLeft: '7px',
                    }}
                  >
                    <FormatListBulletedOutlinedIcon sx={{ fontSize: '30px' }} />
                  </IconButton>
                </Tooltip>
              }
            </div>
          </Box>
          {/*   <div style={{ marginTop: '-60px' }} className='d-flex flex-row-reverse'>
            {
              // Save-List Button
            }
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/journey')}
              className='btn px-5 svg-icon svg-icon-2x' 
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='43' height='43' viewBox='0 0 43 43'>
                <g id='list-circle-outline' transform='translate(1.5 1.5)'>
                  <path
                    id='Path_1769'
                    data-name='Path 1769'
                    d='M104,86a20,20,0,0,0-40,0,21.408,21.408,0,0,0,.171,2.622A20,20,0,0,0,104,86Z'
                    transform='translate(-64 -66)'
                    fill='none'
                    stroke='#f9f9f9'
                    stroke-width='3'
                  />
                  <g id='Group_3359' data-name='Group 3359' transform='translate(-64.715 -65.045)'>
                    <g id='Group_3356' data-name='Group 3356' transform='translate(74.215 76.545)'>
                      <line
                        id='Line_43'
                        data-name='Line 43'
                        x2='11'
                        transform='translate(8.135 1.5)'
                        fill='none'
                        stroke='#f9f9f9'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='3'
                      />
                      <g
                        id='Ellipse_544'
                        data-name='Ellipse 544'
                        fill='none'
                        stroke='#f9f9f9'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                      >
                        <circle cx='1.5' cy='1.5' r='1.5' stroke='none' />
                        <circle cx='1.5' cy='1.5' r='0.5' fill='none' />
                      </g>
                    </g>
                    <g id='Group_3357' data-name='Group 3357' transform='translate(74.215 83.274)'>
                      <line
                        id='Line_105'
                        data-name='Line 105'
                        x2='11'
                        transform='translate(8.135 1.5)'
                        fill='none'
                        stroke='#f9f9f9'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='3'
                      />
                      <g
                        id='Ellipse_904'
                        data-name='Ellipse 904'
                        fill='none'
                        stroke='#f9f9f9'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                      >
                        <circle cx='1.5' cy='1.5' r='1.5' stroke='none' />
                        <circle cx='1.5' cy='1.5' r='0.5' fill='none' />
                      </g>
                    </g>
                    <g id='Group_3358' data-name='Group 3358' transform='translate(74.215 90.004)'>
                      <line
                        id='Line_106'
                        data-name='Line 106'
                        x2='11'
                        transform='translate(8.135 1.5)'
                        fill='none'
                        stroke='#f9f9f9'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='3'
                      />
                      <g
                        id='Ellipse_905'
                        data-name='Ellipse 905'
                        fill='none'
                        stroke='#f9f9f9'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                      >
                        <circle cx='1.5' cy='1.5' r='1.5' stroke='none' />
                        <circle cx='1.5' cy='1.5' r='0.5' fill='none' />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          </div> */}

          <div className='d-flex justify-content-center'>
            <Box
              sx={{
                width: '1680px',
                height: '830px',
                bgcolor: 'white',
                typography: 'body1',
                borderRadius: '15px',
              }}
            >
              {journeyCreateLoding ? (
                <div style={{ width: '100%', height: "100%", display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : <Grid container>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{ width: '100%' }}
                    marginTop={2}
                    marginBottom={2}
                    paddingLeft={20}
                    paddingRight={20}
                  >
                    <Stepper activeStep={step} /* alternativeLabel */>
                      {steps.map((label) => (
                        <Step
                          key={label}
                          sx={{
                            '& .MuiStepIcon-root': {
                              fontSize: '25px',
                              color: '#9E9E9E', // Varsayılan renk
                              fontWeight: 'bold',
                            },
                            '& .Mui-active, & .Mui-completed': {
                              color: '#9c27b0 !important',
                            },
                          }}
                        >
                          <StepLabel
                            sx={{
                              '& .MuiStepLabel-label': {
                                fontSize: '15px', // Label'ların font boyutunu ayarlayın
                              },
                              '& .Mui-active': {
                                fontWeight: 'bold',
                              },
                            }}
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                  {(editJourney || createJourneyId) && (
                    <Box marginRight={10} marginLeft={-10} display={'flex'} alignItems={'center'}>
                      <Typography
                        fontSize={14}
                        fontWeight={'bold'}
                        color={'#343A40'}
                        marginRight={1}
                      >
                        {journeyId || createJourneyId}
                      </Typography>
                      <Chip label={journeyStatus} variant='outlined' size='small' />
                    </Box>
                  )}
                </div>
                {step === 0 && (
                  <Grid xs={12} container marginLeft={2} height={710}>
                    <Grid xs={4} paddingRight={1}>
                      <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                        Select Activities
                      </Typography>
                      <Typography variant='h6' style={{ color: '#a1a5b7' }}>
                        Pick a name and add some activities into the journey. You can add as many
                        activities as you wish.
                      </Typography>
                    </Grid>
                    <Grid xs={4} display={"flex"} flexDirection={"column"} justifyItems={"center"} alignItems={"center"}>
                      {/*  <div style={{height:30,marginLeft:-30}}>
                    {!chatIsOnLoading && <CircularProgress size={20} color='success' sx={{ marginRight: 1 }} />}
                      </div> */}
                      <Box display={"flex"} alignItems={"center"} justifyContent={"start"} width={"100%"} >
                        <Typography fontSize={16} /* marginRight={3} */ color={"#ADB5BD"} >
                          Flow Type
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems={"center"} justifyContent={"start"} width={"100%"}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={journeyFlowType}
                            onChange={(e) => setJourneyFlowType(!journeyFlowType)}
                          >
                            <FormControlLabel disabled={editJourney} value={true} control={<Radio color='secondary' />} label={<Box display={"flex"} alignItems={"center"}>
                              <Typography style={{ fontSize: 18, marginLeft: 3 }} fontWeight={journeyFlowType ? "bold" : ""}>Sequential</Typography>
                              <Typography fontSize={16} marginLeft={1} color={"#ADB5BD"}>Activity is linked to previous activity</Typography>
                            </Box>} />
                            <FormControlLabel disabled={editJourney} value={false} control={<Radio color='secondary' />} label={<Box display={"flex"} alignItems={"center"}>
                              <Typography style={{ fontSize: 18, marginLeft: 3 }} fontWeight={!journeyFlowType ? "bold" : ""}>Free</Typography>
                              <Typography fontSize={16} marginLeft={1} color={"#ADB5BD"}>Activities can be played independently</Typography>
                            </Box>} />
                          </RadioGroup>
                        </FormControl>
                        {/*  <FormControlLabel
                          control={
                            <Android12Switch
                              defaultChecked
                              color='success'
                              value={journeyFlowType}
                              checked={journeyFlowType === true}
                              disabled={journeyFlowTypeDisabled}
                              onChange={(e) => setJourneyFlowType(!journeyFlowType)}
                            
                            />
                          }
                          label=''
                        />
                        <Typography fontSize={16} color={"#707070"} >
                          Sequential
                        </Typography> */}
                      </Box>

                    </Grid>
                    <Grid xs={4} display={'flex'} justifyContent={'center'} marginTop={2} paddingRight={3} >
                      <TextField
                        sx={{ width: '94%' }}
                        size='small'
                        label='Name'
                        required
                        type='text'
                        autoFocus
                        variant='outlined'
                        placeholder='Max 48 characters'
                        /*  placeholder='Pick a name and add some activities into the journey. You can add as many
                       activities as you wish.' */
                        disabled={activities}
                        value={cardName}
                        onChange={(e) => e.target.value.length <= 48 && (setcardName(e.target.value.toUpperCase()), setChanged(true))}
                        inputProps={{ maxLength: 48, /* style: { textAlign: 'center', }, */ }}
                      />
                    </Grid>
                    <Grid xs={12} container padding={1} marginTop={1}>
                      <Grid xs={7}>
                        <Typography
                          variant='h6'
                          fontWeight={'bold'} /* style={{color: '#a1a5b7'}} */
                        >
                          ACTIVITY STORE
                        </Typography>
                        {
                          //DataGrid start
                        }
                        <Box sx={{ height: 550, width: '100%' }}>
                          <StyledDataGrid
                            rows={rows || []}
                            columns={columns || []}
                            rowHeight={50}
                            disableVirtualization
                            checkboxSelection={activities === true ? false : true}
                            keepNonExistentRowsSelected
                            /* onCellDoubleClick={handleCellClick} */
                            onCellClick={handleCellOneClick}
                            getRowId={(row) => row.activityId}
                            disableRowSelectionOnClick
                            sortingMode='server'
                            onSortModelChange={handleSortModelChange}
                            sortModel={activityOptions?.sortModel}
                            rowSelectionModel={selected}
                            onRowSelectionModelChange={handleSelectionModelChange}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                              setColumnVisibilityModel(newModel)
                            }
                            filterMode='server'
                            onFilterModelChange={onFilterChange}
                            rowCount={rowCount}
                            loading={isLoadingData}
                            paginationModel={paginationModel}
                            paginationMode='server'
                            pagination={true}
                            onPaginationModelChange={setPaginationModel}
                            getRowClassName={(params) =>
                              `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''
                              }`
                            }
                            slots={{
                              pagination: CustomPagination,
                            }}
                            sx={{
                              boxShadow: 3,
                              '& .MuiDataGrid-cell:hover': {
                                cursor: 'pointer',
                              },
                              '&	.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                                color: '#A1A5B7',
                                fontSize: 14,
                              },
                              '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                              },
                            }}
                          />
                        </Box>
                        {
                          //dataGrid end
                        }
                      </Grid>
                      <Grid xs={5} display={'flex'} justifyContent={'center'}>
                        <Box width={'100%'} paddingLeft={2} paddingRight={4}>
                          <Typography
                            variant='h6'
                            fontWeight={'bold'} /* style={{color: '#a1a5b7'}} */
                          >
                            SELECTED ACTIVITIES
                          </Typography>
                          <Paper
                            elevation={3}
                            style={{
                              color: 'white',
                              width: '100%',
                              overflowY: 'hidden',
                            }}
                          >
                            <Grid container>
                              <Grid
                                xs={10}
                                height={550}
                                padding={1}
                                style={{ overflowY: 'scroll', overflowX: 'hidden' }}
                              >
                                {activityList?.map((activity: any, index: number) => (
                                  <div
                                    key={index}
                                    onMouseEnter={(e: any) => {
                                      setHoverSelectedActivities(index)
                                    }}
                                    onMouseLeave={(e: any) => {
                                      setHoverSelectedActivities(-1)
                                    }}
                                    onClick={() => {
                                      setselectedCard(activity)
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <Paper
                                      elevation={3}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: index !== 0 ? '10px' : '',
                                        background:
                                          selectedCard?.activityId === activity.activityId
                                            ? '#e2f2fe'
                                            : hoverSelectedActivities === index
                                              ? '#eef7fe'
                                              : '',
                                      }}
                                    >
                                      <Box
                                        display={'flex'}
                                        justifyContent={'start'}
                                        alignItems={'center'}
                                        marginLeft={1}
                                      >
                                        <Typography variant='h6' color={'black'} width={'20px'}>{`${index + 1
                                          }.`}</Typography>
                                        <Avatar
                                          src={activity.icon}
                                          style={{ marginLeft: '10px' }}
                                        ></Avatar>
                                        <Typography
                                          variant='h6'
                                          style={{ marginLeft: '10px', whiteSpace: 'normal' }}
                                          color={'black'}
                                        >
                                          {activity.title}
                                        </Typography>
                                      </Box>

                                      <Box width={'40px'} height={'100%'}>
                                        <Tooltip arrow title='Delete'>
                                          <IconButton
                                            onClick={() => (
                                              setActivityList(
                                                activityList.filter(
                                                  (activityList: any) =>
                                                    activityList.activityId !== activity.activityId
                                                )
                                              ),
                                              setSelected(
                                                selected.filter(
                                                  (activityId: any) =>
                                                    activityId !== activity.activityId
                                                )
                                              ),
                                              setChanged(true)
                                            )}
                                            disabled={activities}
                                            aria-label='delete'
                                          >
                                            <DeleteForeverIcon color='error' />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Paper>
                                  </div>
                                ))}
                              </Grid>
                              {activityList?.length > 0 && (
                                <Grid
                                  xs={2}
                                  height={550}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Box
                                    display={'flex'}
                                    justifyContent={'center'}
                                    flexDirection={'column'}
                                  >
                                    <Tooltip arrow title='Up' placement='top'>
                                      <IconButton
                                        disabled={
                                          activities ||
                                          activityList?.findIndex(
                                            (activity: any) =>
                                              activity.activityId === selectedCard?.activityId
                                          ) === 0
                                        }
                                        onClick={() => goUpper(selectedCard)}
                                        aria-label='Up'
                                      >
                                        <KeyboardArrowUpIcon
                                          style={{ color: '#a1a5b7', fontSize: '50px' }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow title='Down' placement='bottom'>
                                      <IconButton
                                        disabled={
                                          activities ||
                                          activityList?.findIndex(
                                            (activity: any) =>
                                              activity.activityId === selectedCard?.activityId
                                          ) ===
                                          activityList.length - 1
                                        }
                                        onClick={() => goDown(selectedCard)}
                                        aria-label='Down'
                                      >
                                        <KeyboardArrowDownIcon
                                          style={{ color: '#a1a5b7', fontSize: '50px' }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                              )}
                            </Grid>
                          </Paper>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {step === 1 && (
                  <Grid xs={12} container marginLeft={2} height={680}>
                    <Grid xs={4} display={"flex"} alignContent={"start"} justifyContent={"start"} textAlign={"start"}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                          Set time
                        </Typography>
                        <Typography variant='h6' style={{ color: '#a1a5b7' }}>
                          Define the start and stop time of your journey.
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={4} ></Grid>
                    <Grid xs={4} ></Grid>
                    <Grid xs={12}>
                      <div style={{ height: '400px', }}>
                        <JourneyTiming
                          setChanged={setChanged}
                          time={time}
                          time1={time1}
                          dateTime={dateTime}
                          setDateTime={setDateTime}
                          dateTimeEnd={dateTimeEnd}
                          setDateTimeEnd={setDateTimeEnd}
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}
                {step === 2 && (
                  <Grid xs={12} container height={680}>
                    <Grid xs={4} marginLeft={2}>
                      <Typography variant='h5' style={{ fontWeight: 'bold', display: 'flex' }}>
                        Add participants
                        {/*  {cardName.length > 0 && (
                          <Typography variant='h5' marginLeft={'5px'}>
                            {' - ' + cardName}
                          </Typography>
                        )} */}
                      </Typography>
                      <Typography variant='h6' style={{ color: '#a1a5b7' }}>
                        Decide the journey will be public or private and then, assign users.
                      </Typography>
                    </Grid>
                    <Grid xs={4.5} height={60} /*  marginTop={-2}  */ display={"flex"} justifyContent={"space-between"}>
                      <div style={{ display: "flex", alignItems: "start", justifyContent: "center", width: "100%" }}>
                        {assignmentTypeLoading && <CircularProgress size={20} color='secondary' sx={{ marginRight: 1 }} />}
                        <RadioGroup
                          aria-labelledby="assignment-type-radio-buttons-group"
                          name="assignmentType"
                          value={!assignmentType}
                          onChange={(event) => (setAssignmentType(!assignmentType), handleChangeAssigmentType(!assignmentType))}
                        >
                          <FormControlLabel
                            value={false}
                            control={<Radio color="secondary"
                              sx={{
                                '& .MuiSvgIcon-root': { // Radio ikonunu hedef alır
                                  fontSize: 28, // Boyutu artırır
                                },
                              }} />}
                            label={
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography fontSize={20} fontWeight={assignmentType ? "bold" : ""}>
                                  Private
                                </Typography>
                                <Typography fontSize={16} color={assignmentType ? "" : "#707070"} marginLeft={1}>
                                  Only selected users or goups
                                </Typography>
                              </div>
                            }
                            disabled={editJourney || assigmentTypeChange || assignmentTypeLoading || userList.length > 0 || groupList.length > 0}
                          />
                          <FormControlLabel
                            value={true}
                            control={<Radio color="secondary"
                              sx={{
                                '& .MuiSvgIcon-root': { // Radio ikonunu hedef alır
                                  fontSize: 28, // Boyutu artırır
                                },
                              }} />}
                            label={
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography fontSize={20} fontWeight={!assignmentType ? "bold" : ""}>
                                  Public
                                </Typography>
                                <Typography fontSize={16} color={!assignmentType ? "" : "#707070"} marginLeft={1} >
                                  Anyone with the link
                                </Typography>
                              </div>
                            }
                            disabled={editJourney || assigmentTypeChange || assignmentTypeLoading || userList.length > 0 || groupList.length > 0}
                          />
                        </RadioGroup>
                        {/*  <FormControlLabel
                              sx={{ width: "100%", height: "100%" }}
                              control={
                                <Android12Switch
                                  defaultChecked
                                  color='secondary'
                                  disabled={assigmentTypeChange || assignmentTypeLoading || userList.length > 0 || groupList.length > 0}
                                  value={assignmentType}
                                  onChange={() => (setAssignmentType(!assignmentType), handleChangeAssigmentType(!assignmentType))}
                                  checked={
                                    assignmentType === true
                                  }
                                  sx={{
                                    width: 85,
                                    height: 50,
                                    '& .MuiSwitch-switchBase': {
                                      padding: 1.5,
                                      paddingTop: "10px",
                                      '&.Mui-checked': {
                                        transform: 'translateX(34px)',
                                      },
                                    },
                                    '& .MuiSwitch-thumb': {
                                      width: 25,
                                      height: 25,
                                    },
                                    '& .MuiSwitch-track': {
                                      borderRadius: 16,
                                    },

                                  }}
                                />

                              }
                              label=''
                            /> */}

                      </div>

                    </Grid>
                    <Grid xs={3} height={60}>
                      <div className=' d-flex align-items-center justify-content-end' style={{
                        display: "flex",
                        marginRight: 10,
                        marginTop: 20
                      }}>
                        {chatIsOnLoading && <CircularProgress size={20} color='success' sx={{ marginRight: 1 }} />}
                        <Typography fontSize={24} marginRight={3} fontWeight={/* privatePart ? 'bold' : '' */"bold"}>
                          Chat
                        </Typography>
                        <FormControlLabel
                          control={
                            <Android12Switch
                              defaultChecked
                              color='success'
                              value={chatIsOn}
                              checked={chatIsOn}
                              disabled={chatIsOnLoading || chatIsActive}
                              onChange={(e) => handleChangeChat(e)}
                            /*     sx={{
                                  width: 85, // Genişliği ayarlayın
                                  height: 50, // Yüksekliği ayarlayın
                                  '& .MuiSwitch-switchBase': {
                                    padding: 1.5,
                                    paddingTop: "10px",
                                    '&.Mui-checked': {
                                      transform: 'translateX(34px)', // Thumb'ın hareket mesafesini ayarlayın
                                    },
                                  },
                                  '& .MuiSwitch-thumb': {
                                    width: 25, // Thumb genişliği
                                    height: 25, // Thumb yüksekliği
                                  },
                                  '& .MuiSwitch-track': {
                                    borderRadius: 16, // Track yuvarlaklığını ayarlayın
                                    // Track boyutlarını ve diğer stil özelliklerini burada ayarlayabilirsiniz
                                  },
                                }} */
                            />
                          }
                          label=''
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} height={600} marginTop={-7}>
                      {assignmentType ? (
                        <div className=' d-flex align-items-center '>
                          <JourneyAddEditUserGroupModal
                            journeyId={createJourneyId || journeyId}
                            journeyName={cardName}
                            /* setCopyHover={setCopyHover}
                            copyHover={copyHover} */
                            handleGroupLoading={handleGroupLoading}
                            setHandleGroupLoading={setHandleGroupLoading}
                            privateAssignmentType={privateAssignmentType}
                            participantsUser={participantsUser}
                            participantsUser1={participantsUser1}
                            setAssignmentTypeOff={setAssignmentTypeOff}
                            setUserList={setUserList}
                            userList={userList}
                            setGroupList={setGroupList}
                            groupList={groupList}
                          />
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                )}
                {step === 3 && (
                  <Grid container height={680}>
                    <Grid xs={4} marginLeft={2} display={"flex"} alignContent={"start"} justifyContent={"start"} textAlign={"start"}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant='h5' style={{ fontWeight: 'bold', }}>
                          Award
                        </Typography>
                        <Typography variant='h6' style={{ color: '#a1a5b7' }}>
                          Awards have strong positive effects on the  participants. We strongly suggest you to give some incentives.
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={4.5} display={"flex"} alignContent={"center"} justifyContent={"center"}>
                      <RadioGroup
                        aria-labelledby="assignment-type-radio-buttons-group"
                        name="assignmentType"
                        value={!assignmentType}
                      >
                        <FormControlLabel
                          value={false}
                          control={<Radio color="secondary"
                            sx={{
                              '& .MuiSvgIcon-root': { // Radio ikonunu hedef alır
                                fontSize: 28, // Boyutu artırır
                              },
                            }} />}
                          label={
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Typography fontSize={20} fontWeight={assignmentType ? "bold" : ""}>
                                Private
                              </Typography>
                              <Typography fontSize={16} color={"#D5D5D5"} marginLeft={1}>
                                Only selected users or goups
                              </Typography>
                            </div>
                          }
                          disabled={true}
                        />
                        <FormControlLabel
                          value={true}
                          control={<Radio color="secondary"
                            sx={{
                              '& .MuiSvgIcon-root': { // Radio ikonunu hedef alır
                                fontSize: 28, // Boyutu artırır
                              },
                            }} />}
                          label={
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Typography fontSize={20} fontWeight={!assignmentType ? "bold" : ""}>
                                Public
                              </Typography>
                              <Typography fontSize={16} color={"#D5D5D5"} marginLeft={1} >
                                Anyone with the link
                              </Typography>
                            </div>
                          }
                          disabled={true}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid xs={3} ></Grid>
                    <Grid xs={12} height={550} paddingLeft={16} paddingRight={16} width={"100%"} display={"flex"} justifyItems={"center"} >
                      <Award
                        setSelectedAwardValue={setSelectedAwardValue}
                        selectedAwardValue={selectedAwardValue}
                        setAwardData={setAwardData}
                        awardData={awardData}
                        setAwardIsTrue={setAwardIsTrue}
                        awardIsTrue={awardIsTrue}
                        award={award}
                        setChanged={setChanged}
                      />

                    </Grid>
                  </Grid>
                )}
                {step === 4 && (
                  <Grid xs={12} container padding={2} height={680}>
                    <Grid xs={4} display={"flex"} alignContent={"start"} justifyContent={"start"} textAlign={"start"}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant='h5' style={{ fontWeight: 'bold', }}>
                          Summary
                        </Typography>
                        <Typography variant='h6' style={{ color: '#a1a5b7' }}>
                          Check all the properties of the journey
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} marginTop={-8} paddingLeft={16} paddingRight={16} display={"flex"} flexDirection={"column"} textAlign={"center"} justifyContent={"center"} alignContent={"center"}>
                      <Typography fontSize={24} color={"#212529"}>JOURNEY SUMMARY</Typography>
                      <Paper elevation={3} sx={{ width: "100%", padding: 3, borderRadius: "10px" }}   >
                        {
                          awardLoading ? <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "476.33px" }}><CircularProgress color='primary' size={45} sx={{ marginLeft: 1 }} /></Box> :
                            <Grid display={"flex"} alignContent={"center"} justifyContent={"space-between"} >
                              <Grid xs={6} container>
                                {
                                  editJourney &&
                                  <Grid xs={6} paddingRight={1} display={"flex"} justifyContent={"space-between"}>
                                    <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                      Status
                                    </Typography>
                                    <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                      :
                                    </Typography>
                                  </Grid>
                                }
                                {
                                  editJourney &&
                                  <Grid xs={6} display={"flex"} justifyContent={"start"}>
                                    <Typography fontSize={18} fontWeight={"bold"}>
                                      {journeyStatus}
                                    </Typography>
                                  </Grid>
                                }
                                <Grid xs={6} paddingRight={1} display={"flex"} justifyContent={"space-between"}>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    Type
                                  </Typography>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    :
                                  </Typography>
                                </Grid>
                                <Grid xs={6} display={"flex"} justifyContent={"start"}>
                                  <Typography fontSize={18} fontWeight={"bold"}>
                                    {assignmentType ? 'Private' : 'Public'}
                                  </Typography>
                                </Grid>
                                <Grid xs={6} paddingRight={1} display={"flex"} justifyContent={"space-between"}>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    Nr of Participants
                                  </Typography>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    :
                                  </Typography>
                                </Grid>
                                <Grid xs={6} display={"flex"} justifyContent={"start"}>
                                  <Typography fontSize={18} fontWeight={"bold"}>
                                    {selectedUserCount}
                                  </Typography>
                                </Grid>
                                <Grid xs={6} paddingRight={1} display={"flex"} justifyContent={"space-between"}>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    Nr of Activities
                                  </Typography>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    :
                                  </Typography>
                                </Grid>
                                <Grid xs={6} display={"flex"} justifyContent={"start"}>
                                  <Typography fontSize={18} fontWeight={"bold"}>
                                    {activityList?.length}
                                  </Typography>
                                </Grid>
                                <Grid xs={6} paddingRight={1} display={"flex"} justifyContent={"space-between"}>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    Start Time
                                  </Typography>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    :
                                  </Typography>
                                </Grid>
                                <Grid xs={6} display={"flex"} justifyContent={"start"}>
                                  <Typography fontSize={18} fontWeight={"bold"}>
                                    {startDateTime
                                      ? formatUTCDateTime(startDateTime)
                                      : '-'}
                                  </Typography>
                                </Grid>
                                <Grid xs={6} paddingRight={1} display={"flex"} justifyContent={"space-between"}>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    Stop Time
                                  </Typography>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    :
                                  </Typography>
                                </Grid>
                                <Grid xs={6} display={"flex"} justifyContent={"start"}>
                                  <Typography fontSize={18} fontWeight={"bold"}>
                                    {endDateTime
                                      ? formatUTCDateTime(endDateTime)
                                      : '-'}
                                  </Typography>
                                </Grid>
                                <Grid xs={6} paddingRight={1} display={"flex"} justifyContent={"space-between"}>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    Award
                                  </Typography>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    :
                                  </Typography>
                                </Grid>
                                <Grid xs={6} display={"flex"} justifyContent={"start"}>
                                  <Typography fontSize={18} fontWeight={"bold"}>
                                    {selectedAwardValue === 0 ? 'No' : 'Yes'}
                                  </Typography>
                                </Grid>
                                <Grid xs={6} paddingRight={1} display={"flex"} justifyContent={"space-between"}>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    Chat
                                  </Typography>
                                  <Typography fontSize={18} color={"#495057"} fontWeight={"bold"}>
                                    :
                                  </Typography>
                                </Grid>
                                <Grid xs={6} display={"flex"} justifyContent={"start"}>
                                  <Typography fontSize={18} fontWeight={"bold"}>
                                    {chatIsOn ? 'On' : 'Off'}
                                  </Typography>
                                </Grid>



                              </Grid>

                              {(editJourney || isSaved) && !assignmentType ?  ( <Grid xs={6} container >
                                <Grid xs={12} justifyContent={"center"} textAlign={"center"} alignContent={"center"}>
                                  <Typography fontSize={20} color={"#212529"}>{cardName.toUpperCase()}</Typography>
                                  <div
                                    className='d-flex justify-content-center align-items-center'
                                    style={{
                                      padding: '10px',
                                      height: '260px',
                                      width: '260px',
                                      marginLeft: 'auto',
                                      marginRight: 'auto',
                                      backgroundImage:
                                        "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill='%23D8D8D8' fill-opacity='0.3'%3E%3Crect x='0' y='0' width='20' height='20'/%3E%3Crect x='20' y='20' width='20' height='20'/%3E%3C/g%3E%3C/svg%3E\")",
                                      backgroundSize: '20px 20px',
                                    }}
                                  >
                                    <img style={{ height: '90%', width: '90%' }} src={sumData.base64qr} />
                                  </div>
                                  <div style={{ marginRight: "-250px", marginTop: "-30px" }}>
                                    <Tooltip arrow title="Download Qr Code" placement='top'>
                                      <IconButton onClick={handleDownloadQRCode}>
                                        <SaveIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                  <div className='mt-4 ' >
                                    <a
                                      className='text-muted'
                                      style={{ fontSize: '20px' }}
                                      href={sumData.journeyUrl}
                                      target='_blank'
                                    >
                                      {sumData.journeyUrl}
                                    </a>
                                    <span
                                      className='ms-4'
                                      onClick={() => {
                                        handleCopied()
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='18.634'
                                        height='21.652'
                                        viewBox='0 0 18.634 21.652'
                                      >
                                        <g
                                          id='Group_3909'
                                          data-name='Group 3909'
                                          transform='translate(-1208.898 -843.438)'
                                        >
                                          <g id='copy-outline' transform='translate(1209.398 843.938)'>
                                            <rect
                                              id='Rectangle_31'
                                              data-name='Rectangle 31'
                                              width='13.4'
                                              height='16.681'
                                              rx='3'
                                              transform='translate(4.235 3.972)'
                                              fill='rgba(0,0,0,0)'
                                              stroke='#6c757d'
                                              stroke-linejoin='round'
                                              stroke-width='1'
                                            />
                                            <path
                                              id='Path_219'
                                              data-name='Path 219'
                                              d='M22.808,13.4l.02-1.191A2.56,2.56,0,0,0,20.6,9.429H11.981c-1.408.005-2.548,1.425-2.552,3.177V23.329a2.56,2.56,0,0,0,2.233,2.78h.957'
                                              transform='translate(-9.429 -9.429)'
                                              fill='rgba(0,0,0,0)'
                                              stroke='#6c757d'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                              stroke-width='1'
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </span>
                                    {copied ? (
                                      <span className='text-success fs-4 ms-2'> Copied!</span>
                                    ) : null}
                                  </div>
                                </Grid>
                              </Grid>

                              ) : (
                                // QR kod alanı kadar boş alan
                                <Grid xs={12} style={{ height: '370px' }} /> // Yüksekliği QR kod alanına uygun olarak ayarlayın
                              )}
                            </Grid>
                        }

                      </Paper>

                    </Grid>
                    <Grid xs={12}></Grid>
                  </Grid>
                )}
                <Grid xs={12} >
                  <Divider variant='middle' sx={{ borderTop: '1px solid black', marginBottom: 1 }} />
                  <Box
                    display={'flex'}
                    padding={0}
                    /*  marginTop={'-10px'} */
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{ width: '100%' }}
                  >
                    <Button
                      variant='contained'
                      color='inherit'
                      sx={{ marginRight: '20px', marginLeft: '9%' }}
                      onClick={() => setDiscardWarnModal(true)}
                    >
                      Discard
                    </Button>
                    <div style={{ marginRight: '6%' }}>
                      <Button
                        variant='contained'
                        color='inherit'
                        sx={{ marginRight: '20px' }}
                        disabled={step === 0 ? true : false}
                        onClick={() => step !== 0 && setStep(step - 1)}
                      >
                        BACK
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ marginRight: '40px' }}
                        disabled={
                          journeyCreateLoding || step === 0
                            ? activityList?.length < 1 || cardName?.length < 2
                              ? true
                              : false
                            : step === 1
                              ? cardName?.length < 2
                                ? true
                                : dateTimeEnd < dateTime
                              : step === 2
                                ? false
                                : step === 3
                                  ? awardIsTrue
                                    ? awardData.awardMediaText?.length < 2
                                      ? awardData.awardMediaType ||
                                        awardData.awardMediaUrl ||
                                        awardData.file
                                        ? false
                                        : true
                                      : false
                                    : false
                                  : false
                        }
                        onClick={() => {
                          if (changed) {
                            step === 0 && (editJourney ? editFunc() : createJourneyId ? editFunc() : saveFunc())
                            step === 1 && editFunc()
                            step === 2 && editFunc()
                            setChanged(false)
                          }
                          step === 3 && awardFunction()
                          if (step >= 0 && step <= 3) {
                            setStep(step + 1)
                          } else if (step === 4) {
                            if (!isSaved) {
                              editFunc()
                            } else {
                              handleJourneyList()
                            }
                          }
                        }}
                      >
                        {step >= 0 && step <= 3 ? 'Next' : (isSaved ? 'Close' : 'Save')}
                      </Button>
                    </div>
                  </Box>
                </Grid>
              </Grid>}

            </Box>
          </div>
        </div>
      </div>
      {
        //#region Discard WarnModal
      }
      <Dialog
        open={discardWarnModal}
        TransitionComponent={Transition}
        fullWidth
        maxWidth={'xs'}
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        /* onClose={handleClickWarnModalClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Typography color='white' variant='h6'>
              You have unsaved changes.
              <br />
              Do you want to discard, Anyway?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: 'white' }} onClick={() => navigate('/journey')}>
            Yes
          </Button>
          <Button style={{ color: 'white' }} onClick={() => setDiscardWarnModal(false)}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
      {
        //#endregion
      }
    </>
  )
}

export default function Snackbar() {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} maxSnack={3}>
      <JourneyCreate />
    </SnackbarProvider>
  )
}
